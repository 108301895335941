import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingMobileApp from "../../components/header/landing/HeaderLandingMobileApp";
import MobileAppPortfolio from "../../components/portfolio/MobileAppPortfolio";
import MobileAppScreenSlider from "../../components/portfolio/MobileAppScreenSlider";
import TestimonialThree from "../../components/testimonial/TestimonialThree";
import BrandThree from "../../components/brand/BrandThree";
import FooterSeven from "../../components/footer/FooterSeven";
import CopyRightThree from "../../components/footer/CopyRightThree";
import FancyFeatureTewentyThree from "../../components/features/FancyFeatureTewentyThree";
import PricingSeven from "../../components/pricing/PricingSeven";
import HeroBannerFive from "../../components/hero-banner/HeroBannerFive";
import BrandTwo from "../../components/brand/BrandTwo";
import SocialTwo from "../../components/social/SocialTwo";
import {Link} from "react-router-dom";
import FancyVideoFour from "../../components/video/FancyVideoFour";
import FancyFeatureTewentyTwo from "../../components/features/FancyFeatureTewentyTwo";
import FancyFeatureTewentyFour from "../../components/features/FancyFeatureTewentyFour";
import FancyFeatureTewentySeven from "../../components/features/FancyFeatureTewentySeven";

const MobileAppLanding = () => {
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          {" "}
          KemNu | Enabling Community Success Worldwide
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingMobileApp />
      {/* End Mobile App Landing Header */}

      {/* =============================================
            Theme Hero Banner
        ==============================================  */}
      <div className="hero-banner-ten" id="home">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-11 col-md-8 m-auto">
              <h1
                className="hero-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Imagine a Community
              </h1>
              <p
                className="hero-sub-heading"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                where you can engage with people, join events, groups, compete, earn rewards, and gain insights like never before.
              </p>
            </div>
          </div>
          {/* End .row */}

          <div className="d-sm-flex align-items-center justify-content-center button-group">
            <a
              href="https://apps.apple.com/us/app/kemnu/id1448710117"
              className="d-flex align-items-center ios-button"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <img src="images/icon/apple.svg" alt="icon" className="icon" />
              <div>
                <span>Download on the</span>
                <strong>App store</strong>
              </div>
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.kemnu"
              className="d-flex align-items-center ios-button"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="350"
            >
              <img
                src="images/icon/playstore.svg"
                alt="icon"
                className="icon"
              />
              <div>
                <span>Get it on</span>
                <strong>Google play</strong>
              </div>
            </a>

            <a
                href="https://app.kemnu.com"
                className="d-flex align-items-center windows-button"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
            >
              <img src="images/icon/windows.svg" alt="icon" className="icon" />
              <div>
                <span>Open it on</span>
                <strong>Web Browser</strong>
              </div>
            </a>

          </div>
        </div>
        {/* End .container */}

        <div className="icon-box-one">
          <img src="images/logo/logo-39.png" alt="icon" />
        </div>
        <div className="icon-box-two">
          <img src="images/logo/logo-40.png" alt="icon" />
        </div>
        <div className="icon-box-three">
          <img src="images/logo/logo-41.png" alt="icon" />
        </div>
        <div className="icon-box-four">
          <img src="images/logo/logo-42.png" alt="icon" />
        </div>
        <div className="icon-box-five">
          <img src="images/logo/logo-43.png" alt="icon" />
        </div>
        <div className="icon-box-six">
          <img src="images/logo/logo-44.png" alt="icon" />
        </div>
        <div className="icon-box-seven">
          <img src="images/logo/logo-45.png" alt="icon" />
        </div>
        <div className="icon-box-eight">
          <img src="images/logo/logo-46.png" alt="icon" />
        </div>
      </div>
      {/* /.hero-banner-ten */}

      {/* =============================================
				Fancy Feature Twenty Two
			==============================================  */}
      {/*<div*/}
      {/*  className="fancy-feature-twentyTwo mpt-200 pt-130 md-mt-80 sm-pt-100"*/}
      {/*  id="product"*/}
      {/*>*/}
      {/*  <div className="container">*/}
      {/*    <div className="title-style-ten mb-40 md-mb-20">*/}
      {/*      <div className="row align-items-center">*/}
      {/*        <div className="col-lg-7">*/}
      {/*          <h2>The Product we work with.</h2>*/}
      {/*        </div>*/}
      {/*        <div className="col-lg-5">*/}
      {/*          <p className="md-pt-20">*/}
      {/*            Commonly used in the graphic, print& publishing industris for*/}
      {/*            previewing visual mockups.*/}
      {/*          </p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="row justify-content-center">*/}
      {/*      <FancyFeatureTewentyThree />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/* /.fancy-feature-twentyTwo */}

      {/* =============================================
            Video Box Two
        ==============================================  */}
      <div className="video-box-two lg-container mt-200 md-mt-100" id="product">
        <div className="container">
          <FancyVideoFour />
        </div>
      </div>
      {/* /.video-box-two */}


      {/* =============================================
            Fancy Feature Twenty Two
        ==============================================  */}
      <div className="fancy-feature-twentyTwo mt-200 md-mt-120" id="features">
        <div className="container">
          <div className="row">
            <div
                className="col-xl-7 col-md-8 m-auto"
                data-aos="fade-up"
                data-aos-duration="1200"
            >
              <div className="title-style-eight text-center mb-40 md-mb-10">
                <h2>
                  One Platform. For any{" "}
                  <span>
                    community <img src="images/shape/191.svg" alt="shape" />
                  </span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <FancyFeatureTewentySeven />
          </div>


        </div>
      </div>
      {/* /.fancy-feature-twentyTwo */}

      {/* =============================================
            Fancy Feature Twenty Three
        ==============================================  */}
      <div className="fancy-feature-twentyThree pt-170 md-pt-100" id="feature">
        <div className="container">
          <div
              className="title-style-nine text-center pb-180 md-pb-100"
              data-aos="fade-up"
              data-aos-duration="1200"
          >
            <h6>Our Features</h6>
            <h2>
              KemNu is all about enabling
              <span>
                Community Success <img src="images/shape/192.svg" alt="shape" />
              </span>
            </h2>
            <p>
              Our goal is to give your community a personalized experience while reducing effort and increasing engagement.
            </p>
          </div>
          {/* End title */}

          <div className="block-style-twentyThree">
            <div className="row align-items-center">
              <div
                  className="col-lg-6 order-lg-last ms-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
              >
                <div className="screen-container ms-auto">
                  <div
                      className="oval-shape"
                      style={{ background: "#69FF9C" }}
                  ></div>
                  <div
                      className="oval-shape"
                      style={{ background: "#FFF170" }}
                  ></div>
                  <img
                      src="images/assets/oneclickaway.png"
                      alt=""
                      className="shapes shape-one"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                  className="col-lg-5 mt-30 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>One click away</h6>
                  <h3 className="title">Get members to their magic moment 10x faster.</h3>
                  <p>
                    Host everything in one place, so members can get instant value from your community. Conversations, content, events, resources, and more.
                  </p>
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

          <div className="block-style-twentyThree">
            <div className="row">
              <div className="col-lg-6">
                <div
                    className="screen-container me-auto"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                >
                  <div
                      className="oval-shape"
                      style={{ background: "#FFDE69" }}
                  ></div>
                  <div
                      className="oval-shape"
                      style={{ background: "#FF77D9" }}
                  ></div>
                  <img
                      src="images/assets/paymentmanagement.png"
                      alt="screen"
                      className="shapes shape-two"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                  className="col-lg-5 mt-50 ms-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>PAYMENT MANAGEMENT</h6>
                  <h3 className="title">Collect payments & upsell on autopilot.</h3>
                  <p>
                    Start charging for payments in minutes. Use our launch pages, upsells, and affiliate program tools to sell memberships in your sleep.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

          <div className="block-style-twentyThree">
            <div className="row">
              <div
                  className="col-lg-6 order-lg-last ms-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
              >
                <div className="screen-container ms-auto">
                  <div
                      className="oval-shape"
                      style={{ background: "#00F0FF" }}
                  ></div>
                  <div
                      className="oval-shape"
                      style={{ background: "#FC6BFF" }}
                  ></div>
                  <img
                      src="images/assets/targetengagement.png"
                      alt="screen"
                      className="shapes shape-three"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                  className="col-lg-5 mt-40 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>TARGET ENGAGEMENT</h6>
                  <h3 className="title">
                    Gamify your Community.
                  </h3>
                  <p>
                    Boost your community's engagement with Gamificaiton. Let your members compete with each other and earn rewards for community participation.
                    Whether it's by hosting events, posting content, or commenting on posts, members can earn KemNu Koins.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

          <div className="block-style-twentyThree">
            <div className="row">
              <div className="col-lg-6">
                <div
                    className="screen-container me-auto"
                    data-aos="fade-right"
                    data-aos-duration="1200"
                >
                  <div
                      className="oval-shape"
                      style={{ background: "#FFDE69" }}
                  ></div>
                  <div
                      className="oval-shape"
                      style={{ background: "#FF77D9" }}
                  ></div>
                  <img
                      src="images/assets/viewinsights.png"
                      alt="screen"
                      className="shapes shape-two"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                  className="col-lg-5 mt-20 ms-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>VIEW INSIGHTS</h6>
                  <h3 className="title">Actionable Analytics to improve Customer Experience.</h3>
                  <p>
                    Using analytics, identify trends, patterns, and areas for improvement. Community managers
                    can make data-driven decisions that can help community managers to optimize the community and its offerings,
                    leading to increased engagement and growth.
                  </p>
                </div>
                {/* /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

          <div className="block-style-twentyThree">
            <div className="row align-items-center">
              <div
                  className="col-lg-6 order-lg-last ms-auto"
                  data-aos="fade-left"
                  data-aos-duration="1200"
              >
                <div className="screen-container ms-auto">
                  <div
                      className="oval-shape"
                      style={{ background: "#69FF9C" }}
                  ></div>
                  <div
                      className="oval-shape"
                      style={{ background: "#FFF170" }}
                  ></div>
                  <img
                      src="images/assets/assignroles.png"
                      alt=""
                      className="shapes shape-one"
                  />
                </div>
                {/* /.screen-container */}
              </div>
              <div
                  className="col-lg-5 mt-20 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
              >
                <div className="text-wrapper">
                  <h6>Assign Roles</h6>
                  <h3 className="title">Manage your Core Team and assign roles.</h3>
                  <p>
                    Assign roles to team members within the community. These roles come with different levels of permissions, allowing community managers
                    to control who has access to and who can make changes to the community.
                    This is designed to help streamline workflows, improve collaboration,
                    and ensure that the right people have access to the right information.
                  </p>
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyThree */}

        </div>
      </div>
      {/* /.fancy-feature-twentyThree */}


      {/* 	=============================================
            Fancy Feature Twenty Four
        ==============================================  */}
      <div className="fancy-feature-twentyFour pt-50 md-pt-60" id="service">
        <div className="container">
          <div className="title-style-nine text-center">
            <h6>Industries Include</h6>
            <h2>
              KemNu has the ability to serve any{" "}
              <span>
                Customer Segment <img src="images/shape/194.svg" alt="shape" />
              </span>
            </h2>
          </div>
        </div>
        <div className="bg-wrapper mt-100 md-mt-80">
          <a
              href="#feedback"
              className="click-scroll-button scroll-target d-flex justify-content-center"
          >
            <img src="images/shape/200.svg" alt="shape" />
          </a>
          <div className="container">
            <FancyFeatureTewentyFour />
          </div>
          <img
              src="images/shape/195.svg"
              alt="shape"
              className="shapes shape-one"
          />
          <img
              src="images/shape/196.svg"
              alt="shape"
              className="shapes shape-two"
          />
          <img
              src="images/shape/197.svg"
              alt="shape"
              className="shapes shape-three"
          />
          <img
              src="images/shape/198.svg"
              alt="shape"
              className="shapes shape-four"
          />
          <img
              src="images/shape/199.svg"
              alt="shape"
              className="shapes shape-five"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* End .fancy feature twenty four */}

      {/* =============================================
            App Screen Preview
        ==============================================  */}
      <div className="app-screen-preview-one">
        <div className="container">
          <div className="app-preview-slider-one arrow-middle-center ">
            <MobileAppPortfolio />
          </div>
        </div>
        {/* End .container */}

        <img
            src="images/shape/bg5.svg"
            alt="shape"
            className="shapes round-bg"
        />
        <img
            src="images/shape/216.svg"
            alt="shape"
            className="shapes shape-one"
        />
        <img
            src="images/shape/217.svg"
            alt="shape"
            className="shapes shape-two"
        />
        <img
            src="images/shape/218.svg"
            alt="shape"
            className="shapes shape-three"
        />
        <img
            src="images/shape/219.svg"
            alt="shape"
            className="shapes shape-four"
        />
      </div>

      {/* /.app-screen-preview-one  */}

      <div className="partner-slider-two mt-110 md-mt-80">
        <div className="container">
          <p className="text-center">
            Over <span>32K+</span> software businesses growing with KemNu.
          </p>
          <div className="partnerSliderTwo">
            <BrandTwo />
          </div>
          {/* End .partner slider two */}
        </div>
      </div>

      {/* 	=============================================
            Fancy Feature Twenty Five
        ==============================================  */}
      <div className="fancy-feature-twentyFive lg-container pt-50 md-pt-100">
        <div className="container">
          {/*<div className="block-style-twentyFive">*/}
          {/*  <div className="row align-items-center">*/}
          {/*    <div*/}
          {/*      className="col-xl-7 col-lg-6 col-md-10 m-auto"*/}
          {/*      data-aos="fade-right"*/}
          {/*      data-aos-duration="1200"*/}
          {/*    >*/}
          {/*      <div className="screen-container">*/}
          {/*        <img src="images/assets/allinone.png" alt="screen" />*/}
          {/*      </div>*/}
          {/*      /!*  /.screen-container *!/*/}
          {/*    </div>*/}
          {/*    <div*/}
          {/*      className="col-xl-5 col-lg-6"*/}
          {/*      data-aos="fade-left"*/}
          {/*      data-aos-duration="1200"*/}
          {/*    >*/}
          {/*      <div className="text-wrapper ps-xl-5">*/}
          {/*        <h6>*/}
          {/*          Over <span>10+ clients</span>*/}
          {/*        </h6>*/}
          {/*        <h3 className="title">All-in-One Community Platform</h3>*/}
          {/*        <p>*/}
          {/*          with a mission to help community managers optimize their workflows so they can do what they do best, engage!*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*      /!* /.text-wrapper *!/*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/* /.block-style-twentyFive */}

          <div className="block-style-twentyFive mt-200 md-mt-100">
            <div className="row align-items-center">
              <div
                className="col-xl-7 col-lg-6 col-md-10 m-auto text-center text-lg-right order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="screen-container">
                  <img
                    src="images/shape/bg6.svg"
                    alt="shape"
                    className="ms-auto bg-round-shape"
                  />
                  <div className="block-content">
                    <div className="row align-items-center">
                      <div className="col-sm-6">
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/136.svg" alt="" />
                          </div>
                          <h4>
                            Friendly <br /> user interface
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                      </div>
                      {/* End .col */}

                      <div className="col-sm-6">
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/137.svg" alt="" />
                          </div>
                          <h4>
                            Replace <br />
                            20+ Apps
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                        <div className="feature-meta">
                          <div className="icon d-flex align-items-end">
                            <img src="images/icon/138.svg" alt="" />
                          </div>
                          <h4>
                            Automate <br />
                            Admin Tasks
                          </h4>
                        </div>
                        {/* /.feature-meta */}
                      </div>
                      {/* End .col */}
                    </div>
                  </div>
                  {/*  /.block-content */}
                </div>
                {/* /.screen-container */}
              </div>
              <div
                className="col-xl-5 col-lg-6"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <div className="text-wrapper order-lg-first">
                  <h6>
                    <span>Best Solution</span>
                  </h6>
                  <h3 className="title">Why to use KemNu?</h3>
                  <p>Things can be frustrating managing a community. We know! We've heard it all!</p>
                  <ul>
                    <li>Simplify your tasks.</li>
                    <li>Centralize your engagement.</li>
                    <li>Boost your productivity.</li>
                  </ul>
                </div>
                {/*  /.text-wrapper */}
              </div>
            </div>
          </div>
          {/* /.block-style-twentyFive */}
        </div>
      </div>
      {/*  /.fancy-feature-twentyFive */}

      {/* 	=============================================
            App Screen Preview
        ==============================================  */}
      {/*<div className="app-screen-preview-two mt-200 md-mt-130">*/}
      {/*  <div className="container">*/}
      {/*    <div className="title-style-eleven text-center mb-120 md-mb-70">*/}
      {/*      <h2>App Screenshot</h2>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="app-preview-slider-two arrow-none">*/}
      {/*    <MobileAppScreenSlider />*/}
      {/*  </div>*/}
      {/*  /!* /.app-preview-slider-two *!/*/}
      {/*</div>*/}
      {/*/!* /.app-screen-preview-two *!/*/}


      {/*
     =============================================
			Usable Tools Section
		============================================== */}
      <div className="useable-tools-section bg-color mt-140 mb-50 md-mt-80">
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="container">
          <h6 className="font-rubik">Integrates with your tools</h6>
          <h2>
            KemNu is a no-code platform that ties into your existing tools, services, & workflows. Get engaged fast!
          </h2>

          <SocialTwo />
        </div>
        {/* /.container */}
      </div>


      {/* =====================================================
            Pricing Section Seven
        ===================================================== */}
      {/*<div className="pricing-section-seven pt-200 md-pt-130" id="pricing">*/}
      {/*  <div className="container">*/}
      {/*    <div className="row">*/}
      {/*      <div className="col-xl-9 col-lg-10 col-md-10 m-auto">*/}
      {/*        <div className="title-style-eleven text-center mb-40 md-mb-70">*/}
      {/*          <h2>No hidden charges. Choose your plan.</h2>*/}
      {/*        </div>*/}
      {/*        <div className="title-style-eleven text-center mb-50 md-mb-30">*/}
      {/*          <h5>KemNu is free to download. You can create a community for up to 25 people, post on feed Page, host events, and view basic Analytics,</h5>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="pricing-table-area-seven">*/}
      {/*      <div className="row align-items-center justify-content-center">*/}
      {/*        <PricingSeven />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    /!* /.pricing-table-area-seven *!/*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/* /.pricing-section-seven */}

      {/* =====================================================
            Client Feedback Slider Seven
        ===================================================== */}
      <div
        className="client-feedback-slider-seven mt-250 md-mt-100"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <img src="images/logo/kemnulogo.png" alt="icon" className="m-auto" />
                <div className="title-style-eleven text-center mt-30">
                  <h2>See what our clients say about us.</h2>
                </div>
                {/* /.title-style-eleven */}
              </div>
            </div>

            <div className="row">
              <div className="col-xl-8 col-lg-10 m-auto">
                <div className="clientSliderFive  mt-80 md-mt-50">
                  <TestimonialThree />
                </div>
                <img
                  src="images/shape/156.svg"
                  alt="shape"
                  className="shapes shape-one"
                />
                <img
                  src="images/shape/157.svg"
                  alt="shape"
                  className="shapes shape-two"
                />
                <img
                  src="images/shape/158.svg"
                  alt="shape"
                  className="shapes shape-three"
                />
              </div>
            </div>
          </div>
        </div>
        {/*  /.inner-container */}
        <img src="images/shape/bg7.svg" alt="shape" className="bg-image" />
      </div>
      {/* /.client-feedback-slider-six */}

      {/* =====================================================
            Useable Tools
        ===================================================== */}
      <div className="useable-tools-section-three mt-200 mb-200 md-mt-120 md-mb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-ten">
                <h6>Our Partners</h6>
                <h2>Who is using KemNu?</h2>
              </div>
              <p className="sub-text">
                Today, we have many businesses, educational institutions, accelerators, neighborhoods, religious institutions, nonprofits, influencers, mentors, and other community managers using KemNu.
              </p>
            </div>
          </div>
        </div>
        {/* /.container */}
        <div className="logo-wrapper brand-bg-white d-flex flex-wrap justify-content-center align-items-center">
          <BrandThree />
        </div>
        {/* /.logo-wrapper */}
      </div>
      {/* /.useable-tools-section-three */}

      {/* =====================================================
            Fancy Short Banner Twelve
        ===================================================== */}
      <div className="fancy-short-banner-twelve">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-10 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-ten text-center">
                <h2>Love our KemNu app? Download now!</h2>
                <p className="pt-25 pb-45">
                  Try it risk free — we don’t charge any cancellation fees
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-duration="1200" data-aos-delay="150">
            <div className="d-sm-flex align-items-center justify-content-center button-group">
              <a
                  href="https://apps.apple.com/us/app/kemnu/id1448710117"
                  className="d-flex align-items-center ios-button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="200"
              >
                <img src="images/icon/apple-black.svg" alt="icon" className="icon" />
                <div>
                  <span>Download on the</span>
                  <strong>App store</strong>
                </div>
              </a>

              <a
                  href="https://play.google.com/store/apps/details?id=com.kemnu"
                  className="d-flex align-items-center ios-button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="350"
              >
                <img
                    src="images/icon/playstore.svg"
                    alt="icon"
                    className="icon"
                />
                <div>
                  <span>Get it on</span>
                  <strong>Google play</strong>
                </div>
              </a>

              <a
                  href="https://app.kemnu.com"
                  className="d-flex align-items-center windows-button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
              >
                <img src="images/icon/windows.svg" alt="icon" className="icon" />
                <div>
                  <span>Open it on</span>
                  <strong>Web Browser</strong>
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* /.container */}
        <img
          src="images/shape/220.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/221.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* /.fancy-short-banner-twelve */}

      <div className="footer-bg-wrapper">
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="fancy-short-banner-two">
          <div className="container">
            <div className="content-wrapper">
              <div
                  className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
              >
                <h2 className="font-gilroy-bold">
                  Didn’t find the answer? contact us for any questions.
                </h2>
                <Link to="/contact-cs"> Contact us</Link>
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>
      </div>

      {/* =====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default MobileAppLanding;
