import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialThree() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    margin: 30,
  };

  const TestimonilContent = [
    {
      desc: ` 11/10 Recommend! This app is an amazing social platform. I thought that because of COVID and quarantine my social interactions would dramatically decline but KemNu changed that. It had various virtual hangouts that helped me stay sane during quarantine and I made great friends too. It’s a great app and definitely recommend downloading.`,
      reviewerName: "HPatel96",
      designation: "Community Manager",
    },
    {
      desc: `Great way to get to know people! This app is a fantastic way to get to know people with similar interests as you and also to find new hobbies and things to do in your city. Especially during covid, this has been what I needed to stay involved in the community!`,
      reviewerName: "Camel456",
      designation: "Community User",
    },
    {
      desc: `Great New Social App! Love the concept of this app! It’s user friendly, always improving features and makes it easy to connect with others.`,
      reviewerName: "QuaintRevolt",
      designation: "Community User",
    }
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div
          className="item"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="100"
        >
          <p>{val.desc}</p>
          <h6 className="name">{val.reviewerName}</h6>
          <span className="desig">{val.designation}</span>
        </div>
      ))}
    </Slider>
  );
}
