import React from "react";

const FancyFeatureTewentyFour = () => {
  return (
    <div className="row">
      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#3FE193" }}
            >
              <img src="images/icon/124.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Sports & Fitness</h4>
              <p>
                Personal trainers, Gyms Fitness classes, Yoga classes, Golf classes, Sport items renting
              </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FF4F86" }}
            >
              <img src="images/icon/corporate.png" alt="icon" />
            </div>
            <div className="text">
              <h4>Corporate & Startups</h4>
              <p>
                Human Resources, Organizations, NGOs, Accelerators, Incubators, etc.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FFCF39" }}
            >
              <img src="images/icon/126.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Events & Entertainment</h4>
              <p>
                Art classes, Escape rooms, Photographers, Event Coordinators, Weddings, etc.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#6D64FF" }}
            >
              <img src="images/icon/influencers.png" alt="icon" />
            </div>
            <div className="text">
              <h4>Influencers</h4>
              <p>
                Premium, exclusive communities to generate income while engaging with followers.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#E752FF" }}
            >
              <img src="images/icon/mentorship.png" alt="icon" />
            </div>
            <div className="text">
              <h4>Mentorship & Accelerators</h4>
              <p>
                Counselling, Consulting, Coaching, Business, Advisory, Spiritual services &
                more.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#29EEFB" }}
            >
              <img src="images/icon/friends.png" alt="icon" />
            </div>
            <div className="text">
              <h4>Family & Friend Groups</h4>
              <p>Connect all your family and friends in one place, share resources, and plan events together.</p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#32B5FF" }}
            >
              <img src="images/icon/130.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Education System</h4>
              <p>
                Universities, Colleges, Schools, Libraries, Parent meetings,
                Tutoring lessons, etc.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div
        className="col-lg-6 d-flex mb-35"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: "#FFA361" }}
            >
              <img src="images/icon/131.svg" alt="icon" />
            </div>
            <div className="text">
              <h4>Medical services</h4>
              <p>
                Create medical groups, connect with other doctors, communication within hospitals, etc.
              </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default FancyFeatureTewentyFour;
