import React from "react";

const BrandImages = [
  "logo-gsu",
  "logo-GT",
  "logo-uga",
  "logo-daltonstate",
  "logo-ksu",
  "logo-emory",
  "logo-ggc",
];

const BrandThree = () => {
  return (
    <>
      {BrandImages.map((val, i) => (
        <div
          className="logo d-flex align-items-center justify-content-center"
          key={i}
        >
          <img src={`images/logo/${val}.png`} alt="logo" />
        </div>
      ))}
    </>
  );
};

export default BrandThree;
