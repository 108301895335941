import React from "react";

const featuresContent = [
  {
    icon: "feed",
    title: "Feed",
    delayAnimation: "",
  },
  {
    icon: "calendar",
    title: "Events",
    delayAnimation: "100",
  },
  {
    icon: "library",
    title: "Library",
    delayAnimation: "200",
  },
  {
    icon: "security",
    title: "Security",
    delayAnimation: "300",
  },
  {
    icon: "gamification",
    title: "Gamify",
    delayAnimation: "",
  },
  {
    icon: "leaderboard (2)",
    title: "Leaderboard",
    delayAnimation: "100",
  },
  {
    icon: "integrations",
    title: "Integrations",
    delayAnimation: "200",
  },
  {
    icon: "analytics",
    title: "Analytics",
    delayAnimation: "300",
  },
  {
    icon: "branding",
    title: "Branding",
    delayAnimation: "",
  },
  {
    icon: "permissions",
    title: "Permissions",
    delayAnimation: "100",
  },
  {
    icon: "newsletters",
    title: "Newsletters",
    delayAnimation: "200",
  },
  {
    icon: "payments",
    title: "Payments",
    // desc: "Create interactive, error-free quotes.",
    delayAnimation: "300",
  },
];

const FancyFeatureTewentySeven = () => {
  return (
    <div className="row">
      {featuresContent.map((val, i) => (
        <div
          className="col-lg-2 col-sm-6"
          data-aos="fade-up"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <div className="block-style-twentySeven">
            <div className="icon d-flex align-items-end justify-content-center">
              <img src={`images/icon/${val.icon}.png`} alt="icon" />
            </div>
            <h4 className="font-gordita">{val.title}</h4>
            <p>{val.desc}</p>
          </div>
          {/* /.block-style-twentySeven */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureTewentySeven;
