import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import ScrollspyNav from "react-scrollspy-nav";
import FooterSeven from "../../../../components/footer/FooterSeven";
import CopyRightThree from "../../../../components/footer/CopyRightThree";
import HeaderLandingMobileApp from "../../../../components/header/landing/HeaderLandingMobileApp";

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions | KemNu
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingMobileApp />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Terms and Conditions
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                      3. Safety Tips
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                      4. Third Party Links
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                      5. Miscellaneous
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Terms and Conditions</h2>
                    <div className="update-date">LAST UPDATED: 6/29/2023</div>
                    <h3>1. Introduction</h3>
                    <p>
                      Hey guys! Welcome to KemNu’s Terms and Conditions of Use
                      (these “Terms”). Our lawyers insist that we impose rules
                      on users to protect all of our hard work. This is a
                      contract between you and the KemNu Team
                      (as defined further below) and we want you to know
                      yours and our rights before you use the KemNu application
                      (“App”). Please take a few moments to read these Terms
                      before enjoying the App, because once you access, view
                      or use the App, you are going to be legally bound by
                      these Terms (so probably best to read them first!).
                    </p>
                    <h3>2. About us</h3>
                    <p>
                      KemNu is a place for every team’s best thinking.
                      We believe tech startups are some of the most
                      interesting and meaningful places to work. What
                      we lack in size, we make up for with
                      creativity, purpose, and character. We're building
                      a modern college networking platform embracing
                      culture, creativity, and community. As a company
                      founded by designers and architects, we are
                      fanatical about creating high-quality experiences
                      for our customers. We do things our own way — or what
                      we believe is the right way — which has delivered us
                      labels like "lit" and "dope" which we wear proudly.
                    </p>
                    <h3>3. Accessing our Website</h3>
                    <p>
                      Your access to the App, Our Content, and any Member Content,
                      as well as these Terms are governed and interpreted by the
                      laws of the State of Georgia, other than such laws, rules,
                      regulations and case law that would result in the application
                      of the laws of a jurisdiction other than the State of Georgia.
                      By using the App, you are consenting to the exclusive
                      jurisdiction of the courts of the United States and the State
                      of Georgia. You agree that such courts shall have in personam
                      jurisdiction and venue and waive any objection based on
                      inconvenient forum. You agree that you will not file or
                      participate in a class action against us. In the event there
                      is a discrepancy between this English language version and any
                      translated copies of the Terms, the English version shall
                      prevail. The Terms constitute a binding legal agreement between
                      you as the user (“you”) and the KemNu Team (“we” or “us”).
                    </p>
                    <p>
                      You can contact us by emailing{" "}
                      <a href="#">contact@kemnu.com</a>.
                    </p>
                    <h3>4. KemNu Rules</h3>
                    <p>
                      Before you can use our awesome App, you will need to register for
                      an account (“Account”). In order to create an account you must: be
                      at least 13 years old, and be legally permitted to use the App by
                      the laws of your home country. You can create an Account via manual
                      registration, or by using your Facebook login details. If you create
                      an Account using your Facebook login details, you authorize us to
                      access, display and use certain information from your Facebook account
                      (e.g. profile pictures, relationship status, location and information
                      about Facebook friends). For more information about what information
                      we use and how we use it, please check out our Privacy Policy.
                      Unfortunately, we cannot allow you to use another person’s Account
                      without permission - that just wouldn’t be fair!
                    </p>
                    <p>
                      You’ll have great fun on KemNu, but if you feel the need to leave,
                      you can delete your Account at any time by going to the ‘Settings’ page
                      when you are logged in and clicking on the ‘Delete account’ link. Your
                      Account will be deleted immediately but it may take a little while for
                      Your Content (defined below) to be completely removed from the App. We
                      will save your profile information in case you realize you miss us and
                      you decide to restore your Account (which you can do within 30 days of
                      de-activating your Account). If you delete your Account and try to create
                      a new account within this time period using the same credentials, we will
                      re-activate your Account for you. We reserve the right at our sole
                      discretion to terminate or suspend any Account or make use of any
                      operational, technological, legal or other means available to enforce
                      the Terms (including without limitation blocking specific IP addresses),
                      at any time without liability and without the need to give you prior
                      notice. You may not access, tamper with, or use non-public areas of the
                      App or our systems. Certain portions of the App may not be accessible if
                      you have not registered for an Account.
                    </p>
                    <h3>5. Types of Content</h3>
                    <p>
                      There are three types of content that you will be able to access on
                      the App: Content that you upload and provide (“Your Content”); Content
                      that members provide (“Member Content”); and Content that the KemNu Team
                      provides (“Our Content”). There is certain content we can’t allow on KemNu.
                    </p>
                    <p>
                      We want our users to be able to express themselves as much as possible
                      and post all sorts of things on KemNu, but we have to impose restrictions on
                      certain content which: contains language or imagery which could be
                      deemed offensive or is likely to harass, upset, embarrass, alarm or annoy
                      any other person; is obscene, pornographic, violent or otherwise may offend
                      human dignity; is abusive, insulting or threatening, discriminatory or which
                      promotes or encourages racism, sexism, hatred or bigotry; encourages any illegal
                      activity including, without limitation, terrorism, inciting racial hatred or the
                      submission of which in itself constitutes committing a criminal offense; is
                      defamatory or libelous; relates to commercial activities (including, without
                      limitation, sales, competitions and advertising, links to other websites or
                      premium line telephone numbers); involves the transmission of “junk” mail or
                      “spam”; contains any spyware, adware, viruses, corrupt files, worm programmes or
                      other malicious code designed to interrupt, damage or limit the functionality of
                      or disrupt any software, hardware, telecommunications, networks, servers or other
                      equipment, Trojan horse or any other material designed to damage, interfere with,
                      wrongly intercept or expropriate any data or personal information whether from
                      KemNu or otherwise; itself, or the posting of which, infringes any third party’s
                      rights (including, without limitation, intellectual property rights and privacy
                      rights); shows another person which was created or distributed without that person’s
                      consent. KemNu operates a zero-tolerance policy for this kind of content.
                    </p>
                    <h4>Your Content</h4>
                    <p>
                      As Your Content is unique, you are responsible and liable
                      for Your Content and will indemnify, defend, release, and
                      hold us harmless from any claims made in connection with Your
                      Content. Sorry, that was a bit of a mouthful, but you are what
                      you post!
                    </p>
                    <p>
                      You may not display any personal contact or
                      banking information on your individual profile page whether
                      in relation to you or any other person (for example, names, home
                      addresses or postcodes, telephone numbers, email addresses, URLs,
                      credit/debit card or other banking details). If you do choose to
                      reveal any personal information about yourself to other users,
                      whether via email or otherwise, it is at your own risk. We
                      encourage you to use the same caution in disclosing details about
                      yourself to third parties online as you would under any other
                      circumstances.
                    </p>
                    <p>
                      As KemNu is a public community, Your Content
                      will be visible to other users of the App all around the world
                      instantly - so make sure you are comfortable sharing Your Content
                      before you post. As such, you agree that Your Content may be viewed
                      by other users and any person visiting, participating in or who is
                      sent a link to the App (e.g. individuals who receive a link to a
                      user’s profile or shared content from other KemNu Users). By
                      uploading Your Content on KemNu, you represent and warrant to us that
                      you have all necessary rights and licenses to do so, and automatically
                      grant us a non-exclusive, royalty-free, perpetual, worldwide license to
                      use Your Content in any way (including, without limitation, editing,
                      copying, modifying, adapting, translating, reformatting, creating
                      derivative works from, incorporating into other works, advertising,
                      distributing and otherwise making available to the general public such
                      Content, whether in whole or in part and in any format or medium currently
                      known or developed in the future).
                    </p>
                    <p>
                      We may assign and/or sub-license the
                      above license to our affiliates and successors without any further approval
                      from you.
                    </p>
                    <p>
                      We have the right to remove, edit, limit or block access to any
                      of Your Content at any time, and we have no obligation to display or review
                      Your Content.
                    </p>
                    <h4>Member Content</h4>
                    <p>
                      Other members of KemNu will also share content via the App.
                      Member Content belongs to the user who posted the content and
                      is stored on our servers and displayed via the App at the
                      direction of the user providing the Member Content.
                    </p>
                    <p>
                      You do not have any rights in relation to other users’ Member
                      Content, and you may only use other KemNu users’ personal
                      information to the extent that your use of it matches KemNu’s
                      purpose of allowing people to meet one another. You may not use
                      other users’ information for commercial purposes, to spam, to
                      harass, or to make unlawful threats. We reserve the right to
                      terminate your Account if you misuse other users’ information.
                    </p>
                    <p>
                      Member Content is subject to the terms and conditions of
                      Sections 512(c) and/or 512(d) of the Digital Millennium Copyright
                      Act 1998. If you have a complaint about Member Content, please see
                      the Digital Millennium Copyright Act section below for more information.
                    </p>
                    <h4>Our Content</h4>
                    <p>
                      You may be wondering what happens to the rest of the Content on KemNu.
                      Well, it belongs to us! Any other text, content, graphics, user
                      interfaces, trademarks, logos, sounds, artwork, and other intellectual
                      property appearing on KemNu are owned, controlled or licensed by us and
                      are protected by copyright, trademark and other intellectual property
                      law rights. All right, title and interest in and to Our Content remain
                      with us at all times.
                    </p>
                    <p>
                      We grant you a non-exclusive, limited, personal, non-transferable,
                      revocable, license to access and use Our Content, without the right
                      to sublicense, under the following conditions: you shall not
                      use, sell, modify, or distribute Our Content except as permitted by the
                      functionality of the App; you shall not use our name in metatags, keywords
                      and/or hidden text; you shall not create derivative works from Our Content
                      or commercially exploit Our Content, in whole or in part, in any way; and
                      you shall use Our Content for lawful purposes only. We reserve all other rights.
                    </p>
                    <h3>6. Restrictions on the App</h3>
                    <p>
                      You agree to: comply with all applicable laws, including without
                      limitation, privacy laws, intellectual property laws, anti-spam
                      laws, equal opportunity laws and regulatory requirements; use your
                      real name on your profile; use the services in a professional manner.
                    </p>
                    <p>
                      You agree that you will not: act in an unlawful or unprofessional
                      manner including being dishonest, abusive or discriminatory;
                      misrepresent your identity, your current or previous positions,
                      qualifications or affiliations with a person or entity; disclose
                      information that you do not have the consent to disclose; create
                      or operate a pyramid scheme, fraud or other similar practice. We
                      don’t like users misbehaving in the KemNu community – users should
                      not do bad things to other users. Therefore, you can report any abuse
                      or complain about Member Content by contacting us, outlining the abuse
                      and/or complaint. You can also report a user directly from a profile
                      or in chat by clicking the ‘Block & Report’ link.
                    </p>
                    <p>
                      Also, we don’t appreciate users doing bad things to KemNu - we’ve
                      worked hard on our creation, so scraping or replicating any part
                      of the App without our prior consent is expressly prohibited. This
                      includes by any means (automated or otherwise) other than through
                      our currently available, published interfaces - unless you have been
                      specifically allowed to do so in a separate agreement with us.
                    </p>
                    <h3>7. Privacy</h3>
                    <p>
                      For information about how the KemNu Team collects, uses, and
                      shares your personal data, please check out our Privacy
                      Policy – this is important stuff, and makes for great bedtime
                      reading! By using KemNu, you agree that we can use such data in
                      accordance with our Privacy Policy.
                    </p>
                    <h3>8. Push Notifications; Location-Based Features</h3>
                    <p>
                      We may provide you with emails, text messages, push notifications,
                      alerts and other messages related to the App and/or the KemNu services,
                      such as enhancements, offers, products, events, and other promotions.
                      After downloading the App, you will be asked to accept or deny push
                      notifications/alerts. If you deny, you will not receive any push
                      notifications/alerts. If you accept, push notifications/alerts will be
                      automatically sent to you. If you no longer wish to receive push
                      notifications/alerts from the App, you may opt-out by changing your
                      notification settings on your mobile device. With respect to other types
                      of messaging or communications, such as emails, text messages, etc., you
                      can unsubscribe or opt-out by either following the specific instructions
                      included in such communications or by emailing us with your request at
                      contact@kemnu.com.
                    </p>
                    <p>
                      The App may allow access to or make available opportunities for you to
                      view certain content and receive other products, services and/or other
                      materials based on your location. To make these opportunities available
                      to you, the App will determine your location using one or more reference
                      points, such as GPS, Bluetooth and/or software within your mobile device.
                      If you have set your mobile device to disable GPS, Bluetooth or other
                      location determining software or do not authorize the App to access your
                      location data, you will not be able to access such location-specific
                      content, products, services, and materials.
                    </p>
                    <h3>9. Disclaimer</h3>
                    <p>
                      Brace yourselves, this may look daunting but it is very important!
                    </p>
                    <p>
                      THE APP, SITE, OUR CONTENT, AND MEMBER CONTENT ARE ALL PROVIDED TO
                      YOU “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTY OF ANY KIND, EITHER
                      EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO, FITNESS FOR A
                      PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT.
                    </p>
                    <p>
                      SHOULD APPLICABLE LAW NOT PERMIT THE FOREGOING EXCLUSION OF EXPRESS
                      OR IMPLIED WARRANTIES, THEN WE GRANT THE MINIMUM EXPRESS OR IMPLIED
                      WARRANTY REQUIRED BY APPLICABLE LAW. NO ADVICE OR INFORMATION, WHETHER
                      ORAL OR WRITTEN, SHALL CREATE ANY WARRANTY, REPRESENTATION OR GUARANTEE
                      NOT EXPRESSLY STATED IN THIS SECTION.
                    </p>
                    <p>
                      ADDITIONALLY, WE DO NOT MAKE ANY WARRANTIES THAT THE APP OR SITE WILL
                      BE UNINTERRUPTED, SECURE OR ERROR-FREE OR THAT YOUR USE OF THE APP OR
                      SITE WILL MEET YOUR EXPECTATIONS, OR THAT THE APP, SITE, OUR CONTENT,
                      ANY MEMBER CONTENT, OR ANY PORTION THEREOF, IS CORRECT, ACCURATE, OR
                      RELIABLE. YOUR USE OF THE APP OR SITE IS AT YOUR OWN RISK. YOU ARE
                      SOLELY RESPONSIBLE FOR YOUR INTERACTIONS WITH OTHER MEMBERS. THE KemNu
                      Team IS NOT RESPONSIBLE FOR THE CONDUCT OF ANY USER. KemNu DOES NOT
                      CONDUCT CRIMINAL BACKGROUND CHECKS ON ITS MEMBERS.
                    </p>
                    <p>
                      NEITHER US NOR ANY OWNER WILL BE LIABLE FOR ANY DAMAGES, DIRECT, INDIRECT,
                      INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE, INCLUDING, WITHOUT
                      LIMITATION, LOSS OF DATA, INCOME, PROFIT OR GOODWILL, LOSS OF OR DAMAGE
                      TO PROPERTY AND CLAIMS OF THIRD PARTIES ARISING OUT OF YOUR ACCESS TO OR
                      USE OF THE APP, SITE, OUR CONTENT, OR ANY MEMBER CONTENT, HOWEVER, CAUSED,
                      WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE),
                      PROPRIETARY RIGHTS INFRINGEMENT, PRODUCT LIABILITY OR OTHERWISE.
                    </p>
                    <p>
                      THE FOREGOING SHALL APPLY EVEN IF WE WERE ADVISED OF THE POSSIBILITY OF
                      SUCH DAMAGES. IF YOU BECOME DISSATISFIED IN ANY WAY WITH THE APP OR SITE,
                      YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP YOUR USE OF THE APP AND SITE.
                    </p>
                    <p>
                      YOU HEREBY WAIVE ANY AND ALL CLAIMS ARISING OUT OF YOUR USE OF THE APP OR
                      SITE. BECAUSE SOME STATES DO NOT ALLOW THE DISCLAIMER OF IMPLIED WARRANTIES
                      OR THE EXCLUSION OR LIMITATION OF CERTAIN TYPES OF DAMAGES, THESE PROVISIONS
                      MAY NOT APPLY TO YOU. IF ANY PORTION OF THIS LIMITATION ON LIABILITY IS
                      FOUND TO BE INVALID OR UNENFORCEABLE FOR ANY REASON, THEN OUR AGGREGATE
                      LIABILITY SHALL NOT EXCEED ONE HUNDRED DOLLARS ($100).
                    </p>
                    <p>
                      THE LIMITATION OF LIABILITY HEREIN IS A FUNDAMENTAL ELEMENT OF THE BASIS
                      OF THE BARGAIN AND REFLECTS A FAIR ALLOCATION OF RISK. THE APP AND SITE
                      WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS AND YOU AGREE THAT THE
                      LIMITATIONS AND EXCLUSIONS OF LIABILITY, DISCLAIMERS AND EXCLUSIVE REMEDIES
                      SPECIFIED HEREIN WILL SURVIVE EVEN IF FOUND TO HAVE FAILED IN THEIR
                      ESSENTIAL PURPOSE.
                    </p>
                    <h3>10. Indemnity</h3>
                    <p>
                      All the actions you make and the information you post on KemNu remain your
                      responsibility. Therefore, you agree to indemnify, defend, release, and hold
                      us, and our partners, licensors, affiliates, contractors, officers,
                      directors, employees, representatives and agents, harmless from and against
                      any third-party claims, damages (actual and/or consequential), actions,
                      proceedings, demands, losses, liabilities, costs and expenses (including
                      reasonable legal fees) suffered or reasonably incurred by us arising as a
                      result of, or in connection with:
                    </p>
                    <p>
                      any negligent acts, omissions or willful misconduct by you; your
                      access to and use of the App; the uploading or submission of Content
                      to the App by you; any breach of these Terms by you; and/or your
                      violation of any law or of any rights of any third party. We retain
                      the exclusive right to settle, compromise and pay any and all claims
                      or causes of action which are brought against us without your prior
                      consent. If we ask, you will cooperate fully and reasonably as required
                      by us in the defense of any relevant claim.
                    </p>
                    <h3>11. Digital Millennium Copyright Act</h3>
                    <p>
                      The KemNu Team has adopted the following policy towards copyright
                      infringement in accordance with the Digital Millennium Copyright
                      Act (the “DMCA”). If you believe any Member Content or Our Content
                      infringes upon your intellectual property rights, please submit a
                      notification alleging such infringement (“DMCA Takedown Notice”)
                      including the following:
                    </p>
                    <p>
                      A physical or electronic signature of a person authorized to act on
                      behalf of the owner of an exclusive right that is allegedly infringed;
                      Identification of the copyrighted work claimed to have been infringed,
                      or if multiple copyrighted works at a single online site are covered by
                      a single notification, a representative list of such works;
                      Identification of the material claimed to be infringing or to be the
                      subject of infringing activity and that is to be removed or access
                      disabled and information reasonably sufficient to permit the service
                      provider to locate the material; Information reasonably sufficient to
                      permit the service provider to contact you, such as an address,
                      telephone number, and, if available, an electronic mail; A statement
                      that you have a good faith belief that use of the material in the
                      manner complained of is not authorized by the copyright owner, its
                      agent, or the law; and A statement that, under penalty of perjury,
                      the information in the notification is accurate and you are authorized
                      to act on behalf of the owner of the exclusive right that is allegedly
                      infringed. Any DMCA Takedown Notices should be sent to contact@kemnu.com.
                    </p>
                  </div>
                  <div id="opt2">
                    <h2 className="font-gilroy-bold">Privacy policy</h2>
                    <div className="update-date">LAST UPDATED: 6/29/2023</div>
                    <h3>1. Introduction</h3>
                    <p>
                      Welcome to the KemNu Privacy Policy (“Policy”)! This explains
                      how we collect, store, protect, and share your information,
                      and with whom we share it. We suggest you read this in
                      conjunction with our brilliant Terms and Conditions of Use.
                      Whilst you’re enjoying the KemNu mobile application (“App”),
                      we collect some information about you. In addition, you may
                      choose to use the App to share information with other KemNuers,
                      including your friends and contacts (“Users”). We may also need
                      to share your information sometimes.
                    </p>
                    <p>
                      KemNu is a global app, and your information will be sent to and
                      used in the United States regardless of the country you reside in.
                      This Policy explains how we protect your personal data when we
                      transfer it overseas, so please read very carefully!
                    </p>
                    <p>
                      If you have any questions or comments about this Policy, please
                      feel free to contact us at contact@kemnu.com.
                    </p>
                    <h3>2. Collection of Information</h3>
                    <h4>Registration Information</h4>
                    <p>
                      When you download the App and create an account (“Account”),
                      we may collect certain information (“Registration Information”)
                      about you, such as: name; Username; Email address; Cell phone
                      number; Gender identity; Date of birth; Sexual preference;
                      Photographs; Location; and Login information and friend lists
                      for social media accounts that you connect to your KemNu Account
                      (this could include, for example, your Facebook and Instagram accounts
                      (“Social Media Accounts”). You will also be required to create a
                      password in connection with the registration of your Account. Once you
                      register, you will be able to review and change this information at any
                      time by accessing your “Profile” page! You control your user profile and
                      are able to correct or update (other than your email address, cell phone
                      number, date of birth, gender and location (which is automatically updated))
                      your information at any time by just logging in to KemNu.
                    </p>
                    <p>
                      The information we collect helps to enhance the App and verify our Users
                      (Robots are not welcome!!). Registration Information such as your name
                      and username may be visible to the public on your profile page.
                    </p>
                    <h4>Additional Information</h4>
                    <p>
                      We may ask you to provide your full name, address and email address.
                      Such details may be shared with third parties for purposes relating
                      to, for example, any of our loyalty programs, sending gifts or
                      delivery of merchandise.
                    </p>
                    <p>
                      We may also collect additional information in connection with your use
                      of KemNu, for example if you use our super cool electronic messaging
                      function.
                    </p>
                    <p>
                      If you contact our Customer Support team, we will receive your email
                      address, and may track your IP address as well as the information you
                      send to us to help resolve your query. We will keep records of our
                      communications with you, including any complaints that we receive from
                      you about other Users (and from other Users about you).
                    </p>
                    <p>
                      We recommend and encourage you (and all our members) to think carefully
                      about the information you disclose about yourself. We also do not
                      recommend that you put email addresses, URLs, instant messaging details,
                      phone numbers, full names or addresses, credit card details, national
                      identity numbers, drivers’ license details and other sensitive information
                      on your profile which is open to abuse and misuse.
                    </p>
                    <p>
                      Please be careful about posting sensitive details about yourself on your
                      profile such as your religious denomination and health details. While you
                      may voluntarily provide this information to us when you create your profile,
                      including your sexual preferences, there is no requirement to do so. Please
                      remember that photographs that you post on KemNu may reveal information about
                      yourself as well. Where you do upload and choose to tell us sensitive
                      information about yourself, you are explicitly consenting to our processing of
                      your information and making this public to other users.
                    </p>
                    <p>
                      When you post information about yourself or use the messaging function, the
                      amount of personal information you share is at your own risk. Please see
                      Section 4 below for more information on who can access what you post on
                      KemNu. If you post anything that is inconsistent with our Terms and
                      Conditions of Use, we reserve the right to terminate your Account - we
                      really don’t want to have to do this so please play by the rules!
                    </p>
                    <p>
                      For safety and security and to ensure you have the best possible user
                      experience, we require users to verify their accounts and might ask for
                      your phone number. We want to make sure you are not a robot! And we also
                      want to avoid fake KemNu accounts being created which can be used for
                      malicious activities and cybercrime – they threaten the KemNu network
                      and spoil things for everyone. We use a third party provider for account
                      verification who will keep your phone number for up to 90 days for fraud
                      identification purposes across a range of online platforms and services
                      like KemNu’s.
                    </p>
                    <p>
                      To prevent abuse of the app/site, KemNu uses automated decisions to block
                      accounts as part of its anti-spam procedures. In order to do this, our
                      systems check accounts and messages for content that indicates breaches of
                      our Terms and Conditions of Use. If an account or message meets certain
                      criteria that demonstrate that the Terms and Conditions of Use are likely
                      to have been breached, the relevant account will automatically be blocked.
                      All users of accounts that have been blocked will be notified that their
                      account has been blocked and affected users can contact KemNu to contest
                      the decision.
                    </p>
                    <p>
                      If you decide to purchase any of our premium services, we will process
                      your payment information and retain this securely for the prevention of
                      fraud and for audit/tax purposes.
                    </p>
                    <p>
                      Finally, we want to keep in touch with you to make sure you know about
                      the great promotions and offers we have available. If you’ve told us
                      it’s OK, we will use your email address and phone number to send you
                      information on these. You can withdraw this consent at any time via
                      Settings in the app.
                    </p>
                    <h4>Data Storage</h4>
                    <p>
                      By using the App, you acknowledge that KemNu is a global app operating
                      through servers located in a number of countries around the world,
                      including the United States. If you live in a country with data
                      protection laws, the storage of your personal data may not provide you
                      with the same protections as you enjoy in your country of residence.
                    </p>
                    <h4>Geolocation Information</h4>
                    <p>
                      If you turn these features on, when you use your mobile, we will
                      collect information about WiFi access points as well as other location
                      information about your longitude and latitude and may save your device’s
                      coordinates to offer certain features to you. This information helps us
                      identify your physical location and we may use it to personalize the App
                      and make it easier for you to interact with other Users, by enabling the
                      information to be displayed and shared with other members choosing to
                      view “nearby” posts.
                    </p>
                    <p>
                      If you have enabled location services, but wish to turn it off, you
                      can do so by the following methods: iPhone app — settings, privacy,
                      location services, KemNu; Android — settings, location, KemNu,
                      permissions, location Log and Usage Data
                    </p>
                    <p>
                      We keep your personal information only as long as we need it
                      for legitimate business purposes (as set out in Section 11 below)
                      and as permitted by applicable law.
                    </p>
                    <p>
                      In practice, we delete or anonymize your information upon
                      deletion of your account (following the safety retention window),
                      unless: we must keep it to comply with applicable law (for
                      instance, some “traffic data” is kept for one year to comply
                      with statutory data retention obligations); we must keep it
                      to evidence our compliance with applicable law (for instance,
                      records of consents to our Terms, Privacy Policy and other
                      similar consents are kept for five years); there is an
                      outstanding issue, claim or dispute requiring us to keep the
                      relevant information until it is resolved; or the information
                      must be kept for our legitimate business interests, such as fraud
                      prevention and enhancing users’ safety and security. For example,
                      information may need to be kept to prevent a user who was banned
                      for unsafe behavior or security incidents from opening a new
                      account.
                    </p>
                    <h4>Device Information</h4>
                    <p>
                      We may collect information about your device when you use the
                      App including the unique device identifier, device model,
                      operating system, and MAC address. In addition, if you permit
                      us to do so, the App may access your device’s address book
                      solely in order to add someone to your contacts.
                    </p>
                    <h4>Links</h4>
                    <p>
                      We may keep track of how you interact with links available on
                      KemNu including third party services and clients by redirecting
                      clicks or through other means. We may share aggregate click
                      statistics such as how many times a particular link was clicked on.
                    </p>
                    <h4>Cookies</h4>
                    <p>
                      Unlike the chocolate chip kind, these cookies have no calories!
                      They are small data files that are transferred to your computer’s
                      hard disk and are basically a tool that stores information about
                      website visits, recognizes you and your preferences each time you
                      visit KemNu, and ensures site functionality and enables us to provide
                      the services our members request.
                    </p>
                    <p>
                      The cookies we collect enable us to learn how people interact with
                      KemNu, which in turn helps us make a better product for you! Cookies
                      store information about your website visits and can recognize you and
                      your preferences each time you visit the KemNu site. They help us to
                      provide a better service to you!
                    </p>
                    <p>
                      KemNu’s use of cookies and local storage devices is basically
                      related to the performance of KemNu’s site, such as analytics
                      that helps us determine how our site is performing and ensuring a
                      smooth and trouble-free experience for our members and visitors.
                    </p>
                    <p>
                      If for any reason you decide that you do not want all of your
                      KemNu activities to be stored you may set your browser and mobile
                      settings to block cookies and local storage devices, but please
                      remember that if you do so, you may not be able to access all of
                      the features KemNu offers!
                    </p>
                    <p>
                      We use the following types of cookies: Analytics and Research, Performance.
                    </p>
                    <p>
                      <strong>Analytics and Research</strong>: We use Google Analytics
                      to collect information about how visitors use the site. The cookies
                      collect information in an anonymous form, including the number of
                      visitors to the App, where visitors have come from and the pages they
                      visited. For more information about Google’s Privacy Policy, please
                      visit http://www.google.com/intl/en/policies/.
                    </p>
                    <p>
                      <strong>Performance</strong>: We need to use certain cookies and save
                      data to your local storage devices to ensure our members have the best
                      possible experience. They assist with your navigation of our site,
                      ensuring pages load quickly and respond promptly to your requests – no
                      one likes snail pace technology!
                    </p>
                    <p>
                      Our use of cookies and local storage devices, including the specific
                      cookie names, may change over time, but will generally fall into the
                      above categories. We will notify you of any important changes to our
                      use of cookies and local storage devices. Please visit this page
                      regularly so that you are aware of any changes.
                    </p>
                    <p>
                      If you would like to know more about these clever and calorie-free
                      cookies, including flash cookies/local storage devices, the following
                      websites provide useful information: allaboutcookies.org,
                      youronlinechoices.eu,
                      http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
                    </p>
                    <h3>3. Use of Information</h3>
                    <p>
                      Our main goal is to ensure your experience on KemNu is an enjoyable
                      one and you don’t end up getting stung! In order to deliver an
                      enjoyable experience to you, we may use your Registration and other
                      information to: offer you our services and features; contact you with
                      information about the App (e.g., updates and new offerings);
                      personalize the App and the content we deliver to you; conduct research
                      and analytics about how you use and interact with the App; resolve
                      disputes between you and other Users; and investigate fraud, protect
                      our legal rights, and to enforce our Terms & Conditions.
                    </p>
                    <h3>4. Disclosure of Information</h3>
                    <p>
                      Our policy is to not disclose your Registration Information, except
                      in the limited circumstances described here:
                    </p>
                    <p>
                      <strong>Your Consent</strong>: If you consent, we may share or
                      disclose your Registration Information, such as when you use a
                      third-party web client or application to access your Account. Once
                      the user logs into KemNu after agreeing to the terms, the user is
                      thereby accepting to automatically receive messages from other users.
                    </p>
                    <p>
                      <strong>Service Providers</strong>: We engage certain trusted third
                      parties to perform functions and provide services to us. We may share
                      your Registration Information with these third parties, but only for
                      the purposes of performing these functions and providing such services
                      e.g. information technology companies (hardware and software) which
                      provide services to support our products.
                    </p>
                    <p>
                      <strong>Moderators</strong>: To monitor activity on the apps and
                      approve content.
                    </p>
                    <p>
                      <strong>Payment Processing and Telecommunications Companies</strong>:
                      To facilitate payments for our premium services.
                    </p>
                    <p>
                      <strong>Law and Harm</strong>: As we mentioned in the Terms & Conditions,
                      we think it is very important that all KemNuers behave whilst using the
                      App. We will cooperate with all third parties to enforce their
                      intellectual property or other rights. We will also cooperate with law
                      enforcement inquiries from within or outside your country of residence
                      where we are required to by law or to protect the vital interests of a
                      person. This may include preserving or disclosing any of your
                      information, including your Registration Information, if we believe in
                      good faith that it is necessary to comply with a law or regulation, or
                      when we believe that disclosure is necessary to comply with a judicial
                      proceeding, court order, or legal request; to protect the safety of any
                      person; to address fraud, security or technical issues e.g. through
                      anti-spam providers to protect the service from criminal activity or to
                      protect our rights or property or those of third parties. In such cases,
                      we may raise or waive any legal objection or right available to us.
                    </p>
                    <p>
                      <strong>Business Transfers</strong>: In the event that KemNu or any of
                      our affiliates undergoes a business transition or change of ownership,
                      such as a merger, acquisition by another company, re-organization, or
                      sale of all or a portion of its assets, or in the event of insolvency
                      or administration, we may be required to disclose your personal data.
                    </p>
                    <p>
                      <strong>Aggregated Information</strong>: We may share aggregated
                      information with third parties that includes your personal data
                      (but which doesn’t identify you directly) together with other
                      information including log data for industry analysis and
                      demographic profiling. You may opt-out of receiving marketing
                      messages by using the opt-out mechanisms and links provided in
                      each email.
                    </p>
                    <p>
                      We ensure these parties must adhere to strict data protection and
                      confidentiality provisions that are consistent with this Policy.
                      Measures are taken to ensure that the data shared is non-attributable
                      to the greatest extent possible.
                    </p>
                    <h3>5. What Others May See About You</h3>
                    <p>
                      We think our KemNuers are awesome, and we want you to share how
                      awesome you are with the world, so we have built certain features
                      to enable this. Our App is designed to make it easier for you to
                      connect with other Users and to interact with them. You might share
                      information like the photos and messages you send or upload and any
                      metadata provided with those messages, along with any comments or
                      information you provide in connection with your photos.
                    </p>
                    <p>
                      When using KemNu, you should assume that anything you post or submit
                      on the App may be publicly-viewable and accessible, both by Users of
                      the App and non-Users of the App. We want our KemNuers to be careful
                      about posting information that will eventually be made public.
                    </p>
                    <h3>6. Modifying Your Registration Information</h3>
                    <p>
                      You may access or modify the Registration Information you provided at
                      any time or permanently delete your Account (although we really hope
                      you don’t!)
                    </p>
                    <p>
                      When your Account is deactivated, we take reasonable efforts to make
                      sure it is no longer viewable on the App. For up to 30 days it is still
                      possible to restore your Account if it was accidentally or wrongfully
                      deactivated. After 30 days, we begin the process of deleting your Account
                      from our systems. We are not responsible for any information, pictures,
                      comments, or other content that is deleted from our systems resulting
                      from the deactivation of your Account.
                    </p>
                    <p>
                      To prevent abuse and/or misuse of KemNu by a User following termination
                      or deletion of a profile/Account we shall retain such information as we
                      deem in our sole discretion may be necessary to ensure that User does
                      not open a new account and profile in breach of our Terms and Conditions
                      of Use and to ensure compliance with all laws and regulations.
                    </p>
                    <p>
                      <strong>Warning</strong>: Even after you remove information from your profile
                      or delete your account, copies of that information may still be viewable
                      and/or accessed to the extent such information has been previously shared with
                      others, or copied or stored by other Users or to the extent such
                      information has been shared with search engines. We cannot control this,
                      nor do we accept any liability for this. If you have given third-party
                      applications or websites access to your personal information they may
                      retain such information to the extent permitted under their terms of
                      service or privacy policies.
                    </p>
                    <p>
                      Removed and deleted information may persist in backup copies for up to
                      30 days to enable restoration, but will not be available to others in
                      the meantime.
                    </p>
                    <h3>7. Our Policy Towards Age</h3>
                    <p>
                      Although we want as many people as possible to enjoy our creation,
                      you have to be at least 18 years old to use the App - sorry kids,
                      we know KemNu is cool, but you’ll have to come back when you’re
                      old enough!
                    </p>
                    <p>
                      KemNu does not knowingly collect any information about or market
                      to children, minors or anyone under the age of 18. If you are less
                      than 18 years old, we request that you do not submit information to
                      us. If we become aware that a child, minor or anyone under the age
                      of 18 has registered with us and provided us with personal information,
                      we will take steps to terminate that person’s registration and delete
                      their Registration Information from KemNu. If we do delete a profile
                      because you violated our no children’s rules, we may retain your email
                      and IP address to ensure that you do not try to get around our rules
                      by creating a new profile.
                    </p>
                    <h3>8. Changes to This Policy</h3>
                    <p>
                      As KemNu evolves, we may revise this Privacy Policy from time to time.
                      The most current version of the policy will govern our use of your
                      information and will always be at kemnu.com. If we make a change to
                      this policy that, in our sole discretion, is material, we will notify
                      you, for example, via an email to the email associated with your Account
                      or by posting a notice within KemNu. By continuing to access or use the
                      App after those changes become effective, you agree to be bound by the
                      revised Privacy Policy.
                    </p>
                    <h3>9. Security</h3>
                    <p>
                      Here at KemNu we pride ourselves on taking commercially reasonable
                      security measures to help protect your information against loss,
                      misuse, and unauthorized access, or disclosure. We use reasonable
                      security measures to safeguard the confidentiality of your personal
                      information such as secured servers using firewalls.
                    </p>
                    <p>
                      Unfortunately, no website or Internet transmission is ever completely
                      100% secure and even we cannot guarantee that unauthorized access,
                      hacking, data loss or other breaches will never occur, but here are
                      some handy tips to help keep your data secure: Please make sure you
                      log out of your Account after use as you never know who may stumble
                      onto your Account! Please don’t share your social media password with
                      anyone else! Change your social media password periodically. If you
                      ever think someone has had access to your social media password,
                      please report it to the relevant social media platform and change
                      your password immediately. We cannot guarantee the security of your
                      personal data while it is being transmitted to our site and any
                      transmission is at your own risk.
                    </p>
                    <p>
                      WE EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS
                      OR IMPLIED, WITH RESPECT TO ANY BREACHES OF SECURITY, DAMAGE TO YOUR
                      DEVICE, OR ANY LOSS OR UNAUTHORIZED USE OF YOUR REGISTRATION
                      INFORMATION OR OTHER DATA.
                    </p>
                    <h3>10. Third-Party Accounts</h3>
                    <p>
                      If you have registered with KemNu using a third party social media
                      platform, such as Facebook, your KemNu profile will be available to
                      all Users of KemNu whether via our App or our partners’ websites.
                    </p>
                    <p>
                      Remember that when you register with a third party, you are also
                      giving them personal information, so we recommend that you read
                      their privacy policies as KemNu does not control how they use their
                      information.
                    </p>
                    <p>
                      If you have registered on one of these third-party websites, such
                      as Facebook, or you have verified your Account via a third party
                      website, we may connect your KemNu profile with your profile on
                      such a website. We may do this by way of new products and
                      applications which we introduce from time to time, including new
                      products powered by our group companies.
                    </p>
                    <p>
                      If you have created a KemNu profile through a third party website
                      and you do not want to link your KemNu profile to your profile on
                      the third party website, KemNu cannot amend these settings. Please
                      visit the application settings on your third party website profile
                      and follow the instructions to remove the KemNu access permissions.
                    </p>
                    <p>
                      We only use the APIs, OAuth Tokens, Widgets, or other means provided
                      by the applicable Social Media Account to integrate your Social Media
                      Account with our App. As such, we only receive the limited information
                      that each Social Media Account permits to be transferred.
                    </p>
                    <h3>11. Your California Privacy Rights</h3>
                    <p>
                      California’s “Shine the Light” law, Civil Code section 1798.83,
                      requires certain businesses to respond to requests from California
                      customers asking about the businesses’ practices related to
                      disclosing personal information to third parties for the third
                      parties’ direct marketing purposes. If you wish to find out about
                      any rights you may have under California Civil Code section 1798.83,
                      you can write to us at feedback@team.KemNu.com.
                    </p>
                    <p>
                      From time to time we may disclose your contact information to third
                      parties to allow them to market their products or services to you or
                      for other marketing purposes. This may be information we received
                      from you offline and online. If you want us to stop further sharing
                      your email contact information with third parties (if we have your
                      email contact information), you may notify us at
                      feedback@team.KemNu.com. Please follow the instructions provided
                      to you by third parties to unsubscribe from their messages. If you
                      have opted-out as described above, and thereafter you choose to use
                      a service or promotion that requires us to contact you or share your
                      information with a third party, then your previous opt-out preferences
                      will not apply to such service.
                    </p>
                    <p>
                      In addition, under California law, operators of online services
                      are required to disclose how they respond to “do not track”
                      signals or other similar mechanisms that provide consumers the
                      ability to exercise choice regarding the collection of personal
                      information of a consumer over time and across third-party online
                      services, to the extent the operator engages in that collection.
                      At this time, we do not track our Users’ personal information over
                      time and across third-party online services. This law also requires
                      operators of online services to disclose whether third parties may
                      collect personal information about their users’ online activities
                      over time and across different online services when the users use
                      the operator’s service. We do not knowingly permit third parties to
                      collect personal information about an individual User’s online
                      activities over time and across different online services when using
                      the App.
                    </p>
                    <h3>12. Your UK and EU Rights</h3>
                    <p>
                      Under UK and EU law, UK and EU users have the right to lodge a
                      complaint with data protection regulators, and the Information
                      Commissioners’ Office (ICO) is the UK’s lead regulator. You can
                      find out how to raise a concern with the ICO by visiting their
                      website at www.ico.org.uk. If you’re within the EU, you may also
                      get in touch with your local Data Protection Regulator who may
                      liaise with the ICO on your behalf.
                    </p>
                    <p>
                      You have a number of rights under the European Data Protection
                      law if you are an EU citizen.
                    </p>
                    <p>
                      <strong>Right to be informed</strong>: what personal data an
                      organization is processing and why (this notice). <strong>Right
                      of access</strong>: you can request a copy of your data.
                      <strong>Right of rectification</strong>: if the data held is
                      inaccurate, you have the right to have it corrected.
                      <strong>Right to erasure</strong>: you have the right to have your
                      data deleted in certain circumstances.
                      <strong>Right to restrict processing</strong>: in limited
                      circumstances, you have the right to request that processing is
                      stopped but the data retained.
                      <strong>Right to data portability</strong>: you can request a
                      copy of your data in a machine-readable form that can be transferred
                      to another provider. <strong>Right to object</strong>: in certain
                      circumstances (including where data is processed on the basis of
                      legitimate interests or for the purposes of marketing) you may object
                      to that processing. Rights related to automated decision making including
                      profiling: there are several rights in this area where processing
                      carried out on a solely automated basis results in a decision that
                      has legal or significant effects for the individual. In these
                      circumstances, your rights include the right to ensure that
                      there is human intervention in the decision-making process.
                      KemNu has designated a Data Protection Officer and they can be
                      reached by emailing DPO@team.KemNu.com or by post at the
                      following address:
                    </p>
                    <p>
                      The Broadgate Tower Third Floor, 20 Primrose Street, London,
                      United Kingdom, EC2A 2RS.
                    </p>
                    <p>
                      If you want to exercise any of your rights listed above please
                      visit the Feedback page or email us at contact@kemnu.com.
                      You can also contact us by post at the address above.
                    </p>
                    <h4>How does KemNu protect my personal data?</h4>
                    <p>
                      KemNu has implemented appropriate security measures to protect
                      and prevent the loss, misuse, and alteration of the information
                      under our control, including your personal data. Our technical
                      experts at KemNu work hard to ensure your secure use of our site.
                    </p>
                    <p>
                      While we take reasonable precautions against possible security
                      breaches of our website, member database and records no website
                      or Internet transmission is completely secure and we cannot
                      guarantee that unauthorized access, hacking, data loss, or other
                      breaches will never occur. We urge you to take steps to keep your
                      personal data safe (including your password) and to log out of
                      your account after use. If you lose your password or give it out,
                      or another service provider that you use suffers a data breach
                      and you have used the same credentials with that service provider
                      as you have with KemNu, your personal data may be compromised. If
                      that happens, please report it to Support via the Feedback page.
                    </p>
                    <h4>Where is my personal data kept?</h4>
                    <p>
                      We want you to be able to access KemNu wherever you happen to be
                      in the world. To enable us to provide that service, we operate a
                      global network of servers including in the US, Hong Kong, Czech
                      Republic, and Russia. The hardware is located in third-party data
                      centers but is owned by the KemNu group. Data collected by
                      advertising partners may also be held outside the European Economic
                      Area. We ensure that the data is adequately protected by ensuring
                      that valid, legal mechanisms are in place such as: EU approved model
                      clauses (which can be found here), US privacy shield certification
                      (more information can be found here – please note that KemNu is not
                      Privacy Shield certified but we may pass information to third-party
                      service providers or advertising partners who are based in the US
                      and who are Privacy Shield certified); and implementing robust
                      contractual standards. If you want more information relating to the
                      nature of the safeguards we have in place, please email
                      DPO@team.KemNu.com.
                    </p>
                    <h4>Does KemNu collect any other personal data about me?</h4>
                    <p>
                      If you contact our Customer Support team via the Feedback page,
                      we will receive your email address and may track your IP address,
                      as well as the information you send to us to help resolve your
                      query. We will keep records of our communications with you,
                      including any complaints that we receive from you about other
                      users (and from other users about you) for 6 years after the
                      deletion of your account.
                    </p>
                    <h4>Does KemNu use my personal data for any other purpose?</h4>
                    <p>
                      We may use a material that you post on the open access areas
                      of KemNu in advertising and promotional materials on our partner
                      sites and partner products. We believe these uses are in line
                      with our legitimate interests in enabling us to improve our site
                      and better tailor your online experience to meet your needs. If
                      we intend to do this, we will contact you for your prior approval.
                    </p>
                    <p><strong>We use your personal data to resolve disputes, troubleshoot
                      problems and enforce our Terms and Conditions of Use.</strong></p>
                    <p>
                      Under EU and UK data protection laws, we are only permitted to use
                      your data when we have a lawful basis to do so. The sub-sections below
                      provide an overview of the legal bases that we rely on to use your
                      data. Where the legal basis is consent, you can withdraw consent at
                      any time. Where the legal basis is legitimate interests, you have a
                      right to object to our use of your data. We explain in the relevant
                      sections in this Policy how you can withdraw consent or opt-out of
                      certain data uses (where applicable).
                    </p>
                    <p>
                      <strong>• Name, email address, date of birth, location</strong>: KemNu uses
                        this data to provide you with the KemNu social networking
                      service.<br/> <u>Legal Basis</u>: Contractual necessity.
                    </p>
                    <p>
                      <strong>• Optional information that you choose to provide in
                        your profile, which may include information about your sexual
                        preferences, religion, ethnic background, photos etc.
                      </strong>: KemNu uses
                      this data facilitate networking opportunities on
                      the KemNu site.<br/> <u>Legal Basis</u>: Consent.
                    </p>
                    <p>
                      <strong>• Phone number</strong>: KemNu uses
                      this data to verify your identity and prevent fraud and to ensure the
                      safety and security of Users.<br/> <u>Legitimate interests</u>: it is
                      in our legitimate interests to ensure that accounts are not set up
                      fraudulently and to safeguard Users of the site.
                    </p>
                    <p>
                      <strong>• Payment card details</strong>: KemNu uses
                      this data to take payment for premium services.<br/> <u>Contractual necessity and
                      legitimate interests</u>: we have a legitimate interest in receiving payment for
                      our premium services.
                    </p>
                    <p>
                      <strong>• Demographic and location information</strong>: KemNu uses
                      this data to serve targeted advertisements to Users of the
                      App.<br/> <u>Legitimate interests</u>: it is in our legitimate interests to
                      target advertisements so that users see relevant advertisements and to allow
                      us to generate income from advertising revenue.
                    </p>
                    <p>
                      <strong>• Email address and cell phone number</strong>: KemNu uses
                      this data to send you marketing information about our offers and services.
                      <br/> <u>Legitimate interests</u>: it is in our legitimate interests to
                      promote our products and services.
                    </p>
                    <p>
                      <strong>• Wifi access points and location data when you use the App</strong>:
                      KemNu uses this data to show “nearby” location information to you and other
                      users of the site.<br/> <u>Legal Basis</u>: Consent.
                    </p>
                    <p>
                      <strong>• Log and usage data, including IP address, browser type, referring
                        domain, pages accessed, mobile carrier and search terms</strong>: KemNu uses
                      this data to carry out research and analysis to help us improve the App.
                      <br/> <u>Legitimate interests</u>: it is in our interests to analyze the way
                      in which Users are accessing and using our services so that we can further
                      develop the App and improve the service.
                    </p>
                    <p>
                      <strong>• Email address and IP address</strong>: KemNu uses
                      this data to respond to correspondence and queries that you
                      submit to us.<br/> <u>Legitimate interests</u>: it is in our
                      legitimate interests to respond to your queries to ensure
                      that we provide a good service to Users and troubleshoot problems.
                    </p>
                    <p>
                      <strong>• Email address, phone number, IP address, and IP
                        session information, social network ID, username, user
                        agent string</strong>: KemNu uses this data to block
                      accounts as part of our anti-spam procedures.<br/>
                      <u>Legitimate interests</u>: it is in our legitimate
                      interests to prevent unauthorized behavior and to maintain
                      the safety and security of our services.
                    </p>
                  </div>
                  <div id="opt3">
                    <h2 className="font-gilroy-bold">Safety Tips</h2>
                    <div className="update-date">LAST UPDATED: 6/29/2023</div>
                    <h3>1. Introduction</h3>
                    <p>
                      Meeting new people is exciting, but you should always be
                      cautious when interacting with someone you don't know.
                      Use your best judgment and put your safety first, whether
                      you are exchanging initial messages or meeting in person.
                      While you can't control the actions of others, there are
                      things you can do to help you stay safe during your KemNu
                      experience.
                    </p>
                    <h3>2. Online Safety</h3>
                    <p>
                      Never Send Money or Share Financial Information Never send
                      money, especially over wire transfer, even if the person
                      claims to be in an emergency. Wiring money is like sending
                      cash, it's nearly impossible to reverse the transaction or
                      trace where the money went. Never share information that could
                      be used to access your financial accounts. If another user asks
                      you for money, report it to us immediately.
                    </p>
                    <p>
                      For tips on avoiding romance scams, check out some advice from
                      the U.S Federal Trade Commission on the FTC website.
                    </p>
                    <p>
                      Protect Your Personal Information Never share personal
                      information, such as your social security number, home or
                      work address, or details about your daily routine (e.g.,
                      that you go to a certain gym every Monday) with people you
                      don't know. If you are a parent, limit the information that
                      you share about your children on your profile and in early
                      communications. Avoid sharing details such as your children's
                      names, where they go to school, or their ages or genders.
                    </p>
                    <p>
                      Stay on the Platform Keep conversations on the KemNu platform
                      while you're getting to know someone. Users with bad intentions
                      often try to move the conversation to text, messaging apps,
                      email, or phone right away.
                    </p>
                    <p>
                      Be Wary of Long Distance and Overseas Relationships Watch out
                      for scammers who claim to be from your country but stuck
                      somewhere else, especially if they ask for financial help
                      to return home. Be wary of anyone who will not meet in person
                      or talk on a phone/video call, they may not be who they say they
                      are. If someone is avoiding your questions or pushing for a
                      serious relationship without meeting or getting to know you
                      first, that's a red flag.
                    </p>
                    <p>
                      Report All Suspicious and Offensive Behavior You know when
                      someone's crossed the line and when they do, we want to know
                      about it. Block and report anyone that violates our terms. Here
                      are some examples of violations:
                    </p>
                    <p>
                      Requests for money or donations Underage users Harassment,
                      threats, and offensive messages Inappropriate or harmful
                      behavior during or after meeting in person Fraudulent profiles
                      Spam or solicitation including links to commercial websites or
                      attempts to sell products or services You can report any concerns
                      about suspicious behavior from any profile page or messaging
                      window here. For more information, check out our Community Guidelines.
                    </p>
                    <p>
                      Protect Your Account Be sure to pick a strong password, and always
                      be careful when logging into your account from a public or shared
                      computer. KemNu will never send you an email asking for your username
                      and password information, if you receive an email asking for account
                      information, report it immediately.
                    </p>
                    <h3>3. Meeting in Person</h3>
                    <p>
                      Don't Be In A Rush Take your time and get to know the other person
                      before agreeing to meet or chat off KemNu. Don't be afraid to ask
                      questions to screen for any red flags or personal deal breakers. A
                      phone or video call can be a useful screening tool before meeting.
                    </p>
                    <p>
                      Meet in Public and Stay in Public Meet for the first few times in
                      a populated, public place, never at your home, your date's home,
                      or any other private location. If your date pressures you to go to
                      a private location, end the date.
                    </p>
                    <p>
                      Tell Friends and Family About Your Plans Tell a friend or family
                      member of your plans, including when and where you're going. Have
                      your cell phone charged and with you at all times.
                    </p>
                    <p>
                      Be in Control of Your Transportation We want you to be in control
                      of how you get to and from your date so that you can leave whenever
                      you want. If you're driving yourself, it's a good idea to have a
                      backup plan such as a ride-share app or a friend to pick you up.
                    </p>
                    <p>
                      Know Your Limits Be aware of the effects of drugs or alcohol on
                      you specifically, they can impair your judgment and your alertness.
                      If your date tries to pressure you to use drugs or drink more than
                      you're comfortable with, hold your ground and end the date.
                    </p>
                    <p>
                      Don't Leave Drinks or Personal Items Unattended Know where your
                      drink comes from and know where it is at all times, only accept
                      drinks poured or served directly from the bartender or server.
                      Many substances that are slipped into drinks to facilitate sexual
                      assault are odorless, colorless, and tasteless. Also, keep your
                      phone, purse, wallet, and anything containing personal information
                      on you at all times.
                    </p>
                    <p>
                      If You Feel Uncomfortable, Leave It's okay to end the date early
                      if you're feeling uncomfortable. In fact, it's encouraged. And if
                      your instincts are telling you something is off or you feel unsafe,
                      ask the bartender or server for help.
                    </p>
                    <p>
                      LGBTQ+ Travel Be careful while traveling
                    </p>
                    <p>
                      We recognize and believe in the importance of being inclusive of
                      all gender identities and sexual orientations, but the reality is
                      this: nowhere in the world is without potential risk, and some
                      countries have specific laws that target LGBTQ+ people.
                    </p>
                    <p>
                      Check out the laws around you when you travel to a new place and
                      research what types of legal protection, if any, are available to
                      you based on sexual orientation. In the event that you're in unsafe
                      territory, we suggest toggling off. Show me on KemNu, which you can
                      find under the settings page.
                    </p>
                    <p>
                      If you have added a sexual orientation to your profile and choose
                      to be shown on KemNu, we will hide your sexual orientation from
                      your profile until you leave that area.
                    </p>
                    <p>
                      It's important to exercise extra caution if you choose to connect
                      with new people in these countries - as some law enforcement have
                      been known to use dating apps as tools for potential entrapment.
                      Some countries have also recently introduced laws that criminalize
                      communications between individuals on same-sex dating applications
                      or websites and even aggravate penalties if that communication leads
                      to sexual encounters.
                    </p>
                    <p>
                      Visit ILGA World to see the latest sexual orientation laws by country,
                      and consider donating to support their research.
                    </p>
                    <p>
                      Source: ILGA World, Updated March 2019
                    </p>
                    <h3>4. Sexual Health and Consent</h3>
                    <p>
                      Protect Yourself When used correctly and consistently,
                      condoms can significantly reduce the risk of contracting and
                      passing on STI's like HIV. But, be aware of STIs like herpes or
                      HPV that can be passed on through skin-to-skin contact. The risk
                      of contracting some STIs can be reduced through vaccination.
                    </p>
                    <p>
                      Know Your Status Not all STIs show symptoms, and you don't want
                      to be in the dark about your status. Stay on top of your health
                      and prevent the spread of STIs by getting tested regularly. Here's
                      where you can find a clinic near you (US only).
                    </p>
                    <p>
                      Talk About It Communication is everything: Before you get physically
                      intimate with a partner, talk about sexual health and STI testing.
                      And be aware, in some places, it's actually a crime to knowingly pass
                      on an STI. Need help starting the conversation? Here are some tips.
                    </p>
                    <p>
                      Consent All sexual activity must start with consent and should include
                      ongoing check-ins with your partner. Verbal communication can help you
                      and your partner ensure that you respect each other's boundaries.
                      Consent can be withdrawn at any time, and sex is never owed to anyone.
                      Do not proceed if your partner seems uncomfortable or unsure, or if
                      your partner is unable to consent due to the effects of drugs or
                      alcohol. Read more about it here.
                    </p>
                    <p>
                      Resources for Help, Support, or Advice:
                    </p>
                    <p>
                      Remember, even if you follow these tips, no method of risk
                      reduction is perfect. If you have a negative experience,
                      please know that it is not your fault and help is available.
                      Report any incidents KemNu, and consider reaching out to one
                      of the resources below. If you feel you are in immediate danger
                      or need emergency assistance, call 911 (U.S. or Canada) or your
                      local law enforcement agency.
                    </p>
                    <p>
                      • RAINN's National Sexual Assault Hotline 1-800-656-HOPE (4673)
                    </p>
                    <p>
                      • Planned Parenthood 1-800-230-7526
                    </p>
                    <p>
                      • National Domestic Violence Hotline 1-800-799-SAFE (7233)
                      or 1-800-787-3224
                    </p>
                    <p>
                      • National Human Trafficking Hotline 1-888-272-7888 or text 233733
                    </p>
                    <p>
                      • National Sexual Violence Resource Center 1-877-739-3895
                    </p>
                    <p>
                      • National Center for Missing & Exploited Children
                      1-800-THE-LOST (843-5678)
                    </p>
                    <p>
                      • Cyber Civil Rights Initiative 1-844-878-2274
                    </p>
                    <p>
                      • VictimConnect - Crime Victim Resource Center 1-855-4VICTIM
                      (855-484-2856)
                    </p>
                    <p>
                      • FBI Internet Crime Complaint Center
                    </p>
                    <p>
                      • LGBT National Help Center 1-888-843-4564
                    </p>
                    <p>
                      • Trans Lifeline 1-877-565-8860 (US) or 1-877-330-6366 (CA)
                      | www.translifeline.org
                    </p>
                    <p>
                      If you are outside the US:
                    </p>
                    <p>
                      • Click here for additional resources in many of the
                      countries where we operate.
                    </p>
                    <p>
                      • Click here for information regarding international
                      sexual orientation laws from the International Lesbian,
                      Gay, Bisexual, Trans and Intersex Association (ILGA).
                    </p>
                  </div>
                  <div id="opt4">
                    <h2 className="font-gilroy-bold">Third Party Links</h2>
                    <div className="update-date">LAST UPDATED: 6/29/2023</div>
                    <h3>1. Third-Party Stores; Premium Services; In-App Purchases</h3>
                    <p>
                      The App may be dependent on and/or interoperate with third-party
                      owned and/or operated platforms and services, e.g., Apple
                      (iTunes, etc.), Google, Facebook, Twitter, etc. (each, a “Third
                      Party Platform”) and may require that you be a registered member
                      of such Third Party Platforms and provide certain account credentials
                      and other information in order to access the App. By using the App,
                      you agree to comply with any applicable terms, conditions or
                      requirements promulgated by any provider of a Third Party Platform
                      (e.g., Facebook’s Terms of Use, iTunes Store Terms of Use, etc.).
                    </p>
                    <p>
                      We may make certain products and/or services available to users of
                      the App in consideration of a subscription fee or other fees
                      (“Premium Services”), including the ability to purchase products,
                      services, and enhancements, such as the ability to extend your matches
                      (“In-App Products”). If you choose to use Premium Services or purchase
                      In-App Products, you acknowledge and agree that additional terms may
                      apply to your use of, access to and purchase of such Premium Services
                      and In-App Products, and such additional terms are incorporated herein
                      by reference. You may purchase Premium Services and In-App Products
                      through the following payment methods (each, a “Premium Payment Method”):
                      (a) making a purchase through the Apple App Store ®, Google Play or
                      other mobile or web application platforms or storefronts authorized by
                      us (each, a “Third Party Store”), (b) paying with your credit card,
                      debit card, or PayPal account, which will be processed by a third party
                      processor, or (c) adding charges to your mobile carrier bill and
                      remitting payment directly to your carrier. Once you have requested a
                      Premium Service or In-App Product, you authorize us to charge your
                      chosen Premium Payment Method and your payment is non-refundable. If
                      payment is not received by us from your chosen Premium Payment Method,
                      you agree to promptly pay all amounts due upon demand by us. If you
                      want to cancel or change your Premium Payment Method at any time,
                      you can do so either via the payment settings option under your
                      profile or by contacting your mobile service provider. If your
                      chosen Premium Payment Method is via your mobile service provider,
                      then please check with them about their payment terms, as their
                      payment terms will govern how payments to the KemNu Team are made as
                      well as how such payments may be changed or canceled. Your
                      subscription to the KemNu Team’s Premium Services will automatically
                      renew until you decide to cancel in accordance with such terms,
                      except in the case of KemNuCoins where there shall be no automatic
                      renewal. In the event of a conflict between a Third Party Store’s
                      terms and conditions and these Terms, the terms and conditions of the
                      Third Party Store or service provider shall govern and control. We are
                      not responsible and have no liability whatsoever for goods or services
                      you obtain through the Third Party Store, our third-party service
                      providers or other web sites or web pages. We encourage you to make
                      whatever investigation you feel necessary or appropriate before
                      proceeding with any online transaction with any of these third parties.
                    </p>
                    <p>
                      If you choose to purchase an In-App Product, you will be prompted
                      to enter details for your account with the Third Party Store you are
                      using (e.g., Android, Apple, etc.) (“your Mobile Platform Account”),
                      and your Mobile Platform Account will be charged for the Premium
                      Service and/or In-App Product in accordance with the terms disclosed
                      to you at the time of purchase, as well as the general terms
                      applicable to all other in-app purchases made through your Mobile
                      Platform Account (e.g., Android, Apple, etc.).
                    </p>
                    <p>
                      Premium Services and In-App Products may include one-time purchases
                      as well as monthly subscriptions (e.g., a one-month subscription,
                      three-month subscription, six-month subscription, etc.) to
                      additional account features. At the end of the free trial period
                      (if any), you will be charged the price of the subscription and
                      will continue to be charged until you cancel your subscription,
                      except in the case of KemNu Coins where there shall be no automatic
                      renewal. Please note that for Premium Services and In-App Products
                      bought on a subscription basis, your subscription will automatically
                      renew for the same subscription period as you initially purchased
                      (e.g., if you bought an In-App Product on a six-month subscription,
                      your subscription will be automatically renewed for an additional
                      six-months). To avoid any charges for additional periods, you must
                      cancel before the end of the free trial period, subscription period
                      or renewal, as applicable, in accordance with the terms and
                      conditions of your Mobile Platform Account and the terms and
                      conditions of any applicable Third Party Store. The pricing may vary
                      due to a number of factors, such as (but not limited to) promotional
                      offers, loyalty bonuses and other discounts that might apply to your
                      age group.
                    </p>
                    <p>
                      Please note that for Premium Services and In-App Products you will
                      be billed continuously for the subscription or service until you
                      cancel in accordance with your Mobile Platform Account’s or your
                      Premium Payment Method’s terms. In all cases, we are not responsible
                      and have no liability whatsoever for any payment processing errors
                      (including card processing, identity verification, analysis and
                      regulatory compliance) or fees or other service-related issues,
                      including those issues that may arise from inaccurate account
                      information, or products or goods you obtain through your Mobile
                      Platform Account or Third-Party Stores. Further, the KemNu Team does
                      not guarantee those product descriptions or other content and products
                      will be available, accurate, complete, reliable, current or error-free.
                      Descriptions and images of, and references to, products or services
                      (including Premium Services or In-App Products) do not imply our or
                      any of our affiliates’ endorsement of such products or services.
                      Moreover, the KemNu Team and its third party operational service
                      providers reserve the right, with or without prior notice, for any or
                      no reason, to change product descriptions, images, and references; to
                      limit the available quantity of any product; to honor, or impose
                      conditions on the honoring of, any coupon, coupon code, promotional
                      code or other similar promotions; to bar any user from conducting any
                      or all transaction(s); and/or to refuse to provide any user with any
                      product. Further, if we terminate your use of or registration to the
                      App because you have breached these Terms, you shall not be entitled
                      to a refund of any unused portion of any fees, payments or other
                      consideration. We encourage you to review the terms and conditions of
                      the applicable third-party payment processors, Third Party Store or
                      Mobile Platform Account before you make any In-App Products or
                      Premium Service purchases.
                    </p>
                    <h3>2. Third-Party App Store</h3>
                    <p>
                      The following additional terms and conditions apply to you
                      if you download the App from a Third Party Store. To the
                      extent that the other terms and conditions of these Terms
                      are less restrictive than, or otherwise conflict with, the
                      terms and conditions of this Section, the more restrictive
                      or conflicting terms and conditions in this Section will
                      apply, but solely with respect to the App and the Third Party
                      Store. You acknowledge and agree that:
                    </p>
                    <p>
                      These Terms are concluded solely between you and the KemNu Team
                      and not with the providers of the Third Party Store, and the
                      KemNu Team (and not the Third Party Store providers) is solely
                      responsible for the App and the content thereof. To the extent
                      that these Terms provide for usage rules for the App which are
                      less restrictive or in conflict with the applicable terms of
                      service of the Third Party Store from which you obtain the App,
                      the more restrictive or conflicting term of the Third Party
                      Store will take precedence and will apply. The Third Party
                      Store provider has no obligation whatsoever to provide any
                      maintenance and support services with respect to the App. The
                      KemNu Team is solely responsible for any product warranties,
                      whether express or implied by law, to the extent not effectively
                      disclaimed. The Third Party Store provider will have no warranty
                      obligation whatsoever with respect to the App, and any other
                      claims, losses, liabilities, damages, costs or expenses
                      attributable to any failure to conform to any warranty will be the
                      sole responsibility of the KemNu Team. The KemNu Team, not the Third
                      Party Store provider, is responsible for addressing any claims you or
                      any third party may have relating to the App or your possession and/or
                      use of the App, including, but not limited to: (i) product liability
                      claims; (ii) any claim that the App fails to conform to any applicable
                      legal or regulatory requirement; (iii) claims arising under consumer
                      protection or similar legislation; and/or (iv) intellectual property
                      infringement claims. The Third Party Store provider and its subsidiaries
                      are third party beneficiaries of this Agreement, and, upon your acceptance
                      of these Terms, the Third Party Store provider from whom you obtained the
                      App will have the right (and will be deemed to have accepted the right) to
                      enforce these Terms against you as a third party beneficiary thereof.
                    </p>
                  </div>
                  <div id="opt5">
                    <h2 className="font-gilroy-bold">Miscellaneous</h2>
                    <div className="update-date">LAST UPDATED: 6/29/2023</div>
                    <p>
                      There are a few more things we need to mention before you can use KemNu.
                      Please bear with us, we’re nearly done!
                    </p>
                    <p>
                      Firstly, those standard clauses at the end of most contracts (boring, we know)
                    </p>
                    <p>
                      These Terms, which we may amend from time to time, constitute the entire
                      agreement between you and the KemNu Team. The Terms supersede all previous
                      agreements, representations, and arrangements between us (written or oral).
                      Nothing in this clause shall limit or exclude any liability for fraudulent
                      misrepresentation.
                    </p>
                    <p>
                      The KemNu Team has taken reasonable steps to ensure the currency,
                      availability, correctness, and completeness of the information
                      contained on KemNu and provides that information on an “as is”,
                      “as available” basis. The KemNu Team does not give or make any
                      warranty or representation of any kind about the information
                      contained on KemNu, whether express or implied. The use of KemNu
                      and the materials available on it is at your sole risk. The KemNu
                      Team cannot be held responsible for any loss arising from the
                      transmission, use of data, or inaccurate User Content.
                    </p>
                    <p>
                      You are responsible for taking all necessary precautions to ensure
                      that any material you may obtain from KemNu is free of viruses or
                      other harmful components. You accept that KemNu will not be provided
                      uninterrupted or error-free, that defects may not be corrected or
                      that The KemNu Team, or the server that makes it available, are free
                      of viruses or bugs, spyware, Trojan horse or any similar malicious
                      software. The KemNu Team is not responsible for any damage to your
                      computer hardware, computer software, or other equipment or technology
                      including, but without limitation damage from any security breach or
                      from any virus, bugs, tampering, fraud, error, omission, interruption,
                      defect, delay in operation or transmission, computer line or network
                      failure or any other technical or other malfunction.
                    </p>
                    <p>
                      We know our Terms are awesome, but we may have to change them now and again
                    </p>
                    <p>
                      As KemNu grows, we might have to make changes to these Terms so we
                      reserve the right to modify, amend or change the Terms at any time
                      (a “Change”). If we do this then the Changes will be posted on this
                      page and we will indicate the Effective Date of the updates at the
                      bottom of the Terms. In certain circumstances, we may send an email
                      to you notifying you of a Change. You should regularly check this
                      page for notice of any changes – we want our users to be as informed
                      as possible.
                    </p>
                    <p>
                      Your continued use of KemNu following any Change constitutes your
                      acceptance of the Change and you will be legally bound by the new
                      updated Terms. If you do not accept any Changes to the Terms, you
                      should stop using KemNu immediately (uh oh, that’s going to be hard!).
                    </p>
                    <p>
                      Some more legal mumbo jumbo
                    </p>
                    <p>
                      If, for any reason, any of the Terms are declared illegal, invalid
                      or otherwise unenforceable by a court of a competent jurisdiction,
                      then to the extent that term is illegal, invalid or unenforceable,
                      it shall be severed and deleted from the Terms and the remainder of
                      the Terms shall survive, remain in full force and effect and continue
                      to be binding and enforceable.
                    </p>
                    <p>
                      No failure or delay in exercising any right, power or privilege
                      under the Terms shall operate as a waiver of such right or
                      acceptance of any variation of the Terms and nor shall any single
                      or partial exercise by either party of any right, power or privilege
                      preclude any further exercise of the right or the exercise of any
                      other right, power or privilege.
                    </p>
                    <h4>You represent and warrant that:</h4>
                    <p>
                      You are not located in a country that is subject to a U.S. Government
                      embargo, or that has been designated by the U.S. Government as a
                      “terrorist supporting” country; and you are not listed on any U.S.
                      Government list of prohibited or restricted parties. By using the App,
                      you agree and acknowledge that KemNu is a global app operating through
                      servers located in a number of countries around the world, including
                      the United States. If you live in a country with data protection laws,
                      the storage of your personal data may not provide you with the same
                      protections as you enjoy in your country of residence. By submitting
                      your personal information, or by choosing to upgrade the services you
                      use, or by making use of the applications available on KemNu, you agree
                      to the transfer of your personal information to, and storage and
                      processing of your personal information in, any such countries and
                      destinations.
                    </p>
                    <h4>
                      The App may contain links to third-party websites or resources.
                      In such cases, you acknowledge and agree that we are not responsible
                      or liable for:
                    </h4>
                    <p>
                      The availability or accuracy of such websites or resources; or the
                      content, products, or services on or available from such websites
                      or resources. Links to such websites or resources do not imply any
                      endorsement. You acknowledge sole responsibility for and assume all
                      risk arising from your use of any such websites or resources.
                      Framing, in-line linking or other methods of association with the
                      App are expressly prohibited without first obtaining our prior written
                      approval.
                    </p>
                    <p>
                      These Terms and any rights and licenses granted hereunder may not
                      be transferred or assigned by you, but may be assigned by us without
                      restriction.
                    </p>
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      {/*<footer className="theme-footer-one pt-130 md-pt-70">*/}
      {/*  <div className="top-footer">*/}
      {/*    <div className="container">*/}
      {/*      <Footer />*/}
      {/*    </div>*/}
      {/*    /!* /.container *!/*/}
      {/*  </div>*/}
      {/*  /!* /.top-footer *!/*/}

      {/*  <div className="container">*/}
      {/*    <div className="bottom-footer-content">*/}
      {/*      <CopyRight />*/}
      {/*    </div>*/}
      {/*    /!*  /.bottom-footer *!/*/}
      {/*  </div>*/}
      {/*</footer>*/}
      {/* /.theme-footer-one */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
    </div>
  );
};

export default TermsConditions;
