import React, { useState } from "react";
import { Link } from "react-router-dom";
import MegaMenu from "./mega-menu/MegaMenu";
import MegaMenuMobile from "./mega-menu/MegaMenuMobile";
import Scrollspy from "react-scrollspy";
import MegaMenuLanding from "./mega-menu/MegaMenuLanding";

const HeaderThree = () => {
  // For header select menu
  const [click1, setClick1] = useState(false);
  const handleClick1 = () => setClick1(!click1);

  // For Mobile  menu
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
      <>
        {/* =============================================
				Theme Main Menu
			==============================================  */}
        <div
            className={
              navbar
                  ? "theme-main-menu sticky-menu theme-menu-five fixed"
                  : "theme-main-menu sticky-menu theme-menu-five"
            }
        >
          <div className="d-flex align-items-center justify-content-center">
            <div className="logo">
              <Link to="/mobile-app-landing#home">
                <img src="images/logo/kemnu_text_logo.svg" alt="brand" />
              </Link>
            </div>
            {/* End logo */}

            <nav id="mega-menu-holder" className="navbar navbar-expand-lg">
              <div className="container nav-container">
                <div className="mob-header">
                  <button className="toggler-menu" onClick={handleClick}>
                    <div className={click ? "active" : ""}>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </button>
                </div>
                {/* End Header */}

                <div
                    className="navbar-collapse collapse landing-menu-onepage"
                    id="navbarSupportedContent"
                >
                  <div className="d-lg-flex justify-content-between align-items-center">
                    <Scrollspy
                        className="navbar-nav  main-side-nav font-gordita"
                        items={[
                          "home",
                          "product",
                          "features",
                          "pricing",
                          "feedback",
                        ]}
                        currentClassName="active"
                        offset={-90}
                    >
                      <li className="nav-item dropdown position-static">
                        <a
                            className="nav-link dropdown-toggle"
                            href="#home"
                            data-toggle="dropdown"
                        >
                          Home
                        </a>
                        <div className="dropdown-menu">
                          <MegaMenuLanding />
                        </div>
                      </li>
                      <li className="nav-item">
                        <a href="/mobile-app-landing#product" className="nav-link">
                          Product
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/mobile-app-landing#features" className="nav-link">
                          Features
                        </a>
                      </li>

                      <li className="nav-item">
                        <a href="/mobile-app-landing#pricing" className="nav-link">
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="/mobile-app-landing#feedback" className="nav-link">
                          Feedback
                        </a>
                      </li>
                    </Scrollspy>
                  </div>
                </div>
              </div>
            </nav>
            {/* End Navbar */}

            <div className="right-widget">
              <ul className="d-flex align-items-center" >

                {/*<li>*/}
                {/*  <a href="https://app.kemnu.com" className="signIn-action d-flex align-items-center">*/}
                {/*    <img src="images/icon/52.svg" alt="icon" />*/}
                {/*    <span>Login &nbsp;&nbsp;</span>*/}
                {/*  </a>*/}
                {/*</li>*/}

                <li>
                  <div
                      className={
                        click1
                            ? "dropdown download-btn ms-0 style-two show"
                            : "dropdown download-btn ms-0 style-two"
                      }
                  >
                    <button
                        className="dropdown-toggle"
                        onClick={handleClick1}
                        type="button"
                    >
                      Download
                    </button>
                    <div
                        className={click1 ? "dropdown-menu  show" : "dropdown-menu"}
                    >
                      <a
                          className="dropdown-item d-flex align-items-center"
                          href="https://apps.apple.com/us/app/kemnu/id1448710117"
                          onClick={handleClick1}
                      >
                        <img src="images/icon/103.svg" alt="icon" />
                        <span>Apple IOS</span>
                      </a>

                      <a
                          className="dropdown-item d-flex align-items-center"
                          href="https://play.google.com/store/apps/details?id=com.kemnu"
                          onClick={handleClick1}
                      >
                        <img src="images/icon/103.svg" alt="icon" />
                        <span>Android</span>
                      </a>

                      <a
                          className="dropdown-item d-flex align-items-center"
                          href="https://app.kemnu.com"
                          onClick={handleClick1}
                      >
                        <img src="images/icon/104.svg" alt="icon" />
                        <span>Mac & Windows</span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            {/* End right-widget */}
          </div>
        </div>
        {/* /.theme-main-menu */}

        {/* Mobile Menu Start */}
        <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
          <div className="logo order-md-1">
            <Link to="/home">
              <img src="images/logo/kemnu_logo.svg" alt="KemNu" />
            </Link>
            <div className="fix-icon text-dark" onClick={handleClick}>
              <img src="images/icon/close.svg" alt="icon" />
            </div>
            {/* Mobile Menu close icon */}
          </div>

          <Scrollspy
              className="navbar-nav  main-side-nav font-gordita"
              items={["home", "product", "features", "pricing", "feedback"]}
              currentClassName="active"
              offset={-90}
          >
            <li className="nav-item">
              <a href="/mobile-app-landing#home" className="nav-link" onClick={handleClick}>
                Home
              </a>
            </li>
            <li className="nav-item">
              <a href="/mobile-app-landing#product" className="nav-link" onClick={handleClick}>
                Product
              </a>
            </li>
            <li className="nav-item">
              <a href="/mobile-app-landing#features" className="nav-link" onClick={handleClick}>
                Features
              </a>
            </li>

            <li className="nav-item">
              <a href="/mobile-app-landing#pricing" className="nav-link" onClick={handleClick}>
                Pricing
              </a>
            </li>
            <li className="nav-item">
              <a href="/mobile-app-landing#feedback" className="nav-link" onClick={handleClick}>
                Feedback
              </a>
            </li>
          </Scrollspy>
        </div>
        {/* Mobile Menu End */}
      </>
  );
};

export default HeaderThree;
